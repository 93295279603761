.logo-img {
    height: 30px;
    position: relative;
    top: 5px;
    left: 2px;
    margin-right: 4px;
}

.logo-img-select{
    height: 30px;
    position: relative;
    top: 5px;
    left: -9px;
    margin-right: 4px;
    margin-top: -11px !important;
}

.brand-logo{
    margin-top: -18px !important;
}

nav{
    height: 35px;
}

.lf-shipper-select{
    color: blue !important;
}

.lf-nav-div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    height: 35px !important;
}

.select-wrapper {
    position: relative;
    height: 99%;
    width: 400px;
}

.select-wrapper .caret {
    position: absolute;
    fill: #ff3d00 !important;
}

.lf-nav-div>.select-wrapper>.select-dropdown.dropdown-trigger {
    font-weight: 400;
    font-size: 1.8em;
    font-style: italic;
    width: 100%;
    text-align: start;
    border: none;
    padding-left: 12px;
    color: #ff3d00;
    margin-top: -5px !important;
}

.lf-nav-div>.select-wrapper>.dropdown-content[style]{
    position:  relative !important;
    top: -70px;
    height: 40vh;
}

.lf-nav-div>.select-wrapper>.dropdown-content>li{
    margin: 0px;
    padding: 0px;
    min-height: 30px;
}

.lf-nav-div>.select-wrapper>.dropdown-content>li>span{
    color: black !important;
    border-bottom: #ddd 1px dotted;
    margin: 0px;
    padding: 4px;
}
.tabs{
    position: relative;
    top: 1px;
    height: 35px;
}

.tab{
    margin-top: -7px !important; 
}

.customTabs > .tab > a {color: #2196f3; }

.customTabs > .tab > a:hover{color: #2196f3}

.customTabs > .tab:hover{background-color: #eee}

.customTabs > li.active > a{background-color: #dddddd; color: rgb(0, 0, 0);}

.tab-close{
    position: relative;
    top: 12px;
}
.lf-sidebar-toggle {
    position: fixed;
    bottom: 6px;
    left: 6px;
    background-color: white;
    cursor: pointer;
    z-index: 3000;
}

.lf-sidebar-toggle>.material-icons {
    color: black;
    font-size: 2.2em;
}

.lf-sidebar-toggle:hover {
    background-color: black;
}

.lf-sidebar-toggle>.material-icons:hover {
    color: white
}

.sideLink {
    font-size: 13px;
    padding-top: 7px!important;;
    padding-bottom: 7px!important;
}

.sideLink:hover {
    background-color: rgb(236, 236, 236);
    color: white;
}

a{
    text-decoration: none;
}

a:hover {
    -webkit-text-emphasis: unset;
            text-emphasis: unset;
}

.active-link {
    box-shadow:inset 100px 100px 100px 1000px #33679e,
    0 4px 8px 10px rgba(109, 109, 109, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.19) !important;
}

.active-link>a{
    color:white;
    font-weight: 700;
}

#masterSearch{
    margin-left:5px;
    margin-right:0px;
}

.search-wrapper{
    margin-top: 15px;
    margin-right:0px;
}

.carrierListTabs{
    cursor: pointer;
    padding: 5px;
    padding-left: 25px!important;
    font-size: 10px;
    text-align: left;

}

.carrierListTabs:hover{
    background-color: rgb(168, 167, 167);
}
.autocomplete_div__1mrZw {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.autocomplete_container__1cmM6 {
    -webkit-flex: 11 1;
            flex: 11 1;
    max-width: 100%;
}

.autocomplete_open__mQppx {
    background-color: white;
    width: calc(100% - 12px);
    border: solid 1px #ddd;
    z-index: 100;
    position: absolute;
}

.autocomplete_list__2MI3G {
    padding: 6px !important;
}

.autocomplete_smallFormList__Oago1 {
    font-size: 12px!important;
}

.autocomplete_input__4aFq8 {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-bottom: 0px solid black;
    border-radius: 3px;
    height: 30px!important;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.autocomplete_smallFormInput__BbWW5 {
    font-size: 12px!important;
    height: 20px!important;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-bottom: 0px solid black;
    border-radius: 3px;
    outline: none;
    width: 100%;
}

.autocomplete_input__4aFq8.autocomplete_err__3IMr5 {
    border: 1px solid #f44336;
}

.autocomplete_input__4aFq8.autocomplete_err__3IMr5:hover {
    border: 1px solid #f44336;
}

.autocomplete_input__4aFq8.autocomplete_err__3IMr5:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.autocomplete_input__4aFq8:hover {
    border: solid 1px black;
}

.autocomplete_input__4aFq8:focus {
    border: 2px solid #2196f3;
    padding: 0px 0px 0px 4px;
}

.autocomplete_label__1OYEp {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.autocomplete_msg__2Sai9 {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.autocomplete_item__3YVri {
    font-size: 1.15rem;
    left: 6px;
}

.autocomplete_highlighted__1ErLX {
    background-color: #2196f3;
    color: white;
}

.autocomplete_add__2WNNf {
    margin: 0px;
    padding: 0px;
    color: #2196f3;
    background-color: white;
    font-size: 32px;
    border-radius: 6px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.autocomplete_add__2WNNf:focus {
    background-color: white;
    color: #2196f3;
    border: #2196f3 1px solid;
    margin-left: 3px;
}
.RawAutoCompleteSmall_div__3o2F2 {
    padding-right: 0px !important;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.RawAutoCompleteSmall_container__3cRbu {
    -webkit-flex: 11 1;
            flex: 11 1;
    max-width: 100%;
    position: relative;
}

.RawAutoCompleteSmall_open__1ectW {
    background-color: white;
    width: calc(100% - 12px);
    border: solid 1px #ddd;
    z-index: 100;
    position: absolute;
    height: auto !important;
}

.RawAutoCompleteSmall_list__iwzFr {
    padding: 6px !important;
    height: auto !important;
    display: block;
    overflow-y: auto;
    position: relative !important;
}

.RawAutoCompleteSmall_smallFormList__TPoAu {
    font-size: 12px !important;
    height: auto !important;
}

.RawAutoCompleteSmall_input__1cxCF {
    position: relative;
    margin-top: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px !important;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.RawAutoCompleteSmall_smallFormInput__2zYF7 {
    font-size: 12px !important;
    height: 20px !important;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-bottom: 0px solid black;
    border-radius: 3px;
    outline: none;
    width: 100%;
}

.RawAutoCompleteSmall_input__1cxCF.RawAutoCompleteSmall_err__8ss4s {
    border: 1px solid #f44336;
}

.RawAutoCompleteSmall_input__1cxCF.RawAutoCompleteSmall_err__8ss4s:hover {
    border: 1px solid #f44336;
}

.RawAutoCompleteSmall_input__1cxCF.RawAutoCompleteSmall_err__8ss4s:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.RawAutoCompleteSmall_input__1cxCF:hover {
    border: solid 1px black;
}

.RawAutoCompleteSmall_input__1cxCF:focus {
    outline: 1px solid #2196f3 !important;
    box-shadow: 0px 0px 0px 1px #2196f3 inset;
}

.RawAutoCompleteSmall_label__djdnk {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.RawAutoCompleteSmall_msg__3uOxT {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.RawAutoCompleteSmall_item__16dh- {
    padding-top: 8px;
    height: 18px;
    font-size: 1rem;
    left: 6px;
}

.RawAutoCompleteSmall_highlighted__Y3eMu {
    background-color: #2196f3;
    color: white;
}

.RawAutoCompleteSmall_add__2ipbd {
    margin: 0px;
    padding: 0px;
    color: #2196f3;
    background-color: white;
    font-size: 32px;
    border-radius: 6px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.RawAutoCompleteSmall_add__2ipbd:focus {
    background-color: white;
    color: #2196f3;
    border: #2196f3 1px solid;
    margin-left: 3px;
}
.button_div__yMYhb {
    margin: 0px 0px 12px;
    padding: 0px 12px 0px 0px !important;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.button_button__rZ4VW {
    margin: 0px;
    padding: 0px 0px 0px 6px;
    border: none;
    border-radius: 3px !important;
    height: 30px;
    font-size: .75rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    float: left;
    width: 100%;
}

@media (min-width: 800px) {
    .button_button__rZ4VW {
        font-size: 1rem
    }
}

@media(min-width: 1250px) {
    .button_button__rZ4VW {
        font-size: .95rem
    }
}

@media(min-width: 1400px) {
    .button_button__rZ4VW {
        font-size: 1rem;
    }
}

.button_icon__2ks_f {
    font-size: 24px;
    margin-left: 4px;
    max-width: 24px;
    float: left;
}

.button_round__2wwq2 {
    border-radius: 18px;
}

.button_filled__FSq4d {
    box-shadow: 0px 2px 2px #888;
    background-color: #eee;
}

.button_outline__3Tbj5 {
    background-color: transparent;
    border: 1px solid black;
    box-shadow: 0px 2px 2px #ccc;
}

.button_outline__3Tbj5.button_black__YPlgy {
    color: black;
    background-color: white;
    border: 1px solid black;

}

.button_black__YPlgy:hover,
.button_black__YPlgy:focus {
    color: white;
    background-color: black;
    box-shadow: 0px 2px 2px #ccc;
}

.button_flat__3chgN {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.button_orange__1BGy_ {
    color: #e65100;
    background-color: white;
}

.button_orange__1BGy_:hover,
.button_orange__1BGy_:focus {
    color: white;
    background-color: #e65100;
    box-shadow: 0px 2px 2px #ccc;
}

.button_outline__3Tbj5.button_orange__1BGy_ {
    color: #e65100;
    background-color: white;
    border: 1px solid #e65100;
}

.button_outline__3Tbj5.button_orange__1BGy_:hover,
.button_outline__3Tbj5.button_orange__1BGy_:focus {
    color: white;
    background-color: #e65100;
    border: 1px solid white;
}

.button_blue__nZAcO {
    color: #2196f3;
    background-color: white;
}

.button_blue__nZAcO:hover,
.button_blue__nZAcO:focus {
    color: white;
    background-color: #2196f3;
    box-shadow: 0px 2px 2px #ccc;
}

.button_outline__3Tbj5.button_blue__nZAcO {
    color: #2196f3;
    background-color: white;
    border: 1px solid #2196f3;
    /* border: none; */
}

.button_outline__3Tbj5.button_blue__nZAcO:hover,
.button_outline__3Tbj5.button_blue__nZAcO:focus {
    color: white;
    background-color: #2196f3;
    border: 1px solid white;
}

.button_filledBlue__1mp65 {
    color: white;
    background-color: #2196f3;
}

.button_filledBlue__1mp65:hover,
.button_filledBlue__1mp65:focus {
    color: #2196f3;
    background-color: white;
    box-shadow: 0px 2px 2px #ccc;
}

.button_outline__3Tbj5.button_filledBlue__1mp65 {
    color: white;
    background-color: #2196f3;
    border: 1px solid #2196f3;
}

.button_outline__3Tbj5.button_filledBlue__1mp65:hover,
.button_outline__3Tbj5.button_filledBlue__1mp65:focus {
    color: #2196f3;
    background-color: white;
    border: 1px solid #2196f3;
}

.button_filledGreen__2f7fr {
    color: white;
    background-color: #4caf50;
}

.button_filledGreen__2f7fr:hover,
.button_filledGreen__2f7fr:focus {
    color: #4caf50;
    background-color: white;
    box-shadow: 0px 2px 2px #ccc;
}

.button_outline__3Tbj5.button_filledGreen__2f7fr {
    color: white;
    background-color: #4caf50;
    border: 1px solid #4caf50;
}

.button_outline__3Tbj5.button_filledGreen__2f7fr:hover,
.button_outline__3Tbj5.button_filledGreen__2f7fr:focus {
    color: #4caf50;
    background-color: white;
    border: 1px solid #4caf50;
}

.button_filledBlack__294qA {
    color: white;
    background-color: black;
}

.button_filledBlack__294qA:hover,
.button_filledBlack__294qA:focus {
    color: black;
    background-color: white;
    box-shadow: 0px 2px 2px #ccc;
}

.button_outline__3Tbj5.button_filledBlack__294qA {
    color: white;
    background-color: black;
    border: 1px solid black;
}

.button_outline__3Tbj5.button_filledBlack__294qA:hover,
.button_outline__3Tbj5.button_filledBlack__294qA:focus {
    color: black;
    background-color: white;
    border: 1px solid black;
}

.button_red__1_ivA {
    color: white;
    background-color: #f44336;
}

.button_red__1_ivA:hover,
.button_red__1_ivA:focus {
    color: #f44336;
    background-color: white;
}

.button_outline__3Tbj5.button_red__1_ivA {
    color: #f44336;
    background-color: white;
    border: 1px solid #f44336;
}

.button_outline__3Tbj5.button_red__1_ivA:hover,
.button_outline__3Tbj5.button_red__1_ivA:focus {
    color: white;
    background-color: #f44336;
    border: 1px solid white;
}

.button_filledRed__1mgAt {
    color: white;
    background-color: #ff0000;
}

.button_filledRed__1mgAt:hover,
.button_filledRed__1mgAt:focus {
    color: #ff0000;
    background-color: white;
}

.button_outline__3Tbj5.button_filledRed__1mgAt {
    color: white;
    background-color: #ff0000;
    border: 1px solid white;
}

.button_outline__3Tbj5.button_filledRed__1mgAt:hover,
.button_outline__3Tbj5.button_filledRed__1mgAt:focus {
    color: rgb(255, 0, 0);
    background-color: #ffffff;
    border: 1px solid rgb(255, 0, 0);
}

.button_white__1QnW3 {
    color: white;
    background-color: #333;
}

.button_white__1QnW3:hover,
.button_white__1QnW3:focus {
    color: #333;
    background-color: white;
}

.button_outline__3Tbj5.button_white__1QnW3 {
    color: #333;
    background-color: white;
    border: 1px solid #333;
}

.button_outline__3Tbj5.button_white__1QnW3:hover,
.button_outline__3Tbj5.button_white__1QnW3:focus {
    color: white;
    background-color: #333;
    border: 1px solid white;
}

.button_green__3vgIl {
    color: #4caf50;
    background-color: white;
}

.button_green__3vgIl:hover,
.button_green__3vgIl:focus {
    color: white;
    background-color: #4caf50;
}

.button_outline__3Tbj5.button_green__3vgIl {
    color: #4caf50;
    background-color: white;
    border: 1px solid #4caf50;
    /* border: none; */
}

.button_outline__3Tbj5.button_green__3vgIl:hover,
.button_outline__3Tbj5.button_green__3vgIl:focus {
    color: white;
    background-color: #4caf50;
    border: 1px solid white;
}

.button_gray__3mcWs {
    color: #9e9e9e;
    background-color: white;
}

.button_gray__3mcWs:hover,
.button_gray__3mcWs:focus {
    color: white;
    background-color: #9e9e9e;
}

.button_outline__3Tbj5.button_gray__3mcWs {
    color: #9e9e9e;
    background-color: white;
    border: 1px solid #9e9e9e;
}

.button_outline__3Tbj5.button_gray__3mcWs:hover,
.button_outline__3Tbj5.button_gray__3mcWs:focus {
    color: white;
    background-color: #9e9e9e;
    border: 1px solid white;
}

.button_filledGray__vl9jn {
    color: white;
    background-color: #9e9e9e;
}

.button_filledGray__vl9jn:hover,
.button_filledGray__vl9jn:focus {
    color: #9e9e9e;
    background-color: white;
}

.button_outline__3Tbj5.button_filledGray__vl9jn {
    color: white;
    background-color: #9e9e9e;
    border: 1px solid white;
}

.button_outline__3Tbj5.button_specialGray__3C-2g {
    color: #9e9e9e;
    background-color: white;
    border: #9e9e9e;
}

.button_disabled__2r9YV {
    background-color: #ddd !important;
    color: #777 !important;
    border: #777 !important;
    box-shadow: none !important;
    cursor: not-allowed;
}

.button_disabled__2r9YV>i {
    cursor: not-allowed;
}

.button_address__1jqXf {
    float: left;
    margin-top: 50px;
}

.button_modalFormat__3RbQ8 {
    margin-top: 30px;
    text-align: center;
}

.button_removeMargin__1hU0X {
    margin-bottom: 0px;
}

.button_commercialInvoiceButtons__3Y3Z9 {
    margin-bottom: 0px;
    text-align: center;
    margin-left: -20px !important;
    margin-right: 40px;
}

.button_cooButton__2DCm3 {
    margin-bottom: 0px;
    margin-top: 10px;
}

.button_toolTip__2GBb0:hover {
    position: relative;
}

.button_toolTip__2GBb0 .button_toolTipText__qBh-C {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 4000 !important;
    border-radius: 15px 0px 15px 15px;
}

.button_toolTip__2GBb0:hover .button_toolTipText__qBh-C {
    visibility: visible;
}
.checkbox_div__2rA5V {
    position: relative;
    padding-right: 12px;
    height: 30px;
    width: 100%;
}

.checkbox_innerDiv__1tAJ8 {
    height: 36px;
    width: auto;
    padding: 0px 0px;
    margin-left: -10px;
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 20px;
    padding-bottom: 5px;
}

.checkbox_innerDiv__1tAJ8:hover {
    width: auto;
}

.checkbox_span__7SXp5 {
    font-size: 14px !important;
    padding-left: 30px !important;
    padding-top: -10px !important;
}

.checkbox_span__7SXp5:hover {
    background-color: rgb(212, 212, 212)
}

.checkbox_label__3RtGn {
    -webkit-transform: scale(.8);
            transform: scale(.8);
    width: auto !important;
    max-width: auto !important;
}

.checkbox_p__3jIAp {
    height: 100%;
    width: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.checkbox_span__7SXp5 {
    font-size: 14px !important;
    padding-left: 30px !important;
}

.checkbox_span__7SXp5:hover {
    background-color: rgb(212, 212, 212);
}

.checkbox_label__3RtGn {
    -webkit-transform: scale(.8);
            transform: scale(.8)
}

input {
    will-change: transform;
}
.checkboxSmall_div__hudfi {
    margin: 0px;
    padding: 0px 6px 0px 0px!important;
    position: relative;
}

.checkboxSmall_innerDiv__3SlRU {
    height: 16px;
    width: 100%;
    padding: 0px 0px;
    margin-left: -10px;
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 20px;
}

.checkboxSmall_innerDiv__3SlRU:hover {
    width: 100%;
}

.checkboxSmall_span__2Og8Y{
    font-size: 17px !important;
    padding-left: 30px !important;
}

.checkboxSmall_span__2Og8Y:hover{
    background-color: rgb(212,212,212);

}


.checkboxSmall_p__1epJG {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 8px;
}

.checkboxSmall_span__2Og8Y:hover{
        background-color: rgb(212, 212, 212);
}

.checkboxSmall_label__2OQwE{
    -webkit-transform: scale(.6);
            transform: scale(.6)
}

input{
    will-change: transform;
}

.date_div__2ji6U {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.date_input__3oZ0O {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.date_input__3oZ0O.date_err__IRus3 {
    border: 1px solid #f44336;
}

.date_input__3oZ0O.date_err__IRus3:hover {
    border: 1px solid #f44336;
}

.date_input__3oZ0O.date_err__IRus3:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.date_input__3oZ0O:hover {
    border: solid 1px black;
}

.date_input__3oZ0O:focus {
    border: 2px solid #2196f3;
    padding: 0px 0px 0px 4px;
}

.date_label__2Cr1P {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.date_msg__1UI_1 {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.date_highlight__2vn9Z{
    background-color: yellow;
}
.textinput_div__16Z17 {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.textinput_input__3C_bz {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.textinput_input__3C_bz.textinput_err__1CREa {
    border: 1px solid #f44336;
}

.textinput_input__3C_bz.textinput_err__1CREa:hover {
    border: 1px solid #f44336;
}

.textinput_input__3C_bz.textinput_err__1CREa:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.textinput_input__3C_bz:hover {
    border: solid 1px black;
}

.textinput_input__3C_bz:focus {
    padding: 0px 0px 0px 4px;
    border-color: transparent;
    box-shadow: 0 0 0 2px #2196f3;
}

.textinput_label__1WXaC {
    height:16px;
    margin: 0px;
    padding: 0px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.textinput_msg__10gUV {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.textinput_disabled__3c0xf{
    background-color: #eee;
}

.textinputSmall_div__2kGDH {
    margin-top: 2px !important;
    padding: 0px 6px 0px 0px !important;
    position: relative;
}

.textinputSmall_input__1LUvz {
    position: relative;
    margin: 0px;
    margin-top: -10px !important;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.textinputSmall_input__1LUvz.textinputSmall_err__2OgiS {
    border: 1px solid #f44336;
}

.textinputSmall_input__1LUvz.textinputSmall_err__2OgiS:hover {
    border: 1px solid #f44336;
}

.textinputSmall_input__1LUvz.textinputSmall_err__2OgiS:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.textinputSmall_input__1LUvz:hover {
    border: solid 1px black;
}

.textinputSmall_input__1LUvz:focus {
    border: 2px solid #2196f3;
    padding: 0px 0px 0px 4px;
}

.textinputSmall_label__3MpOV {
    margin: 0px;
    padding: 0px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -6px;
    height: 13px;
    background-color: white;
    font-size: .6rem;
}

.textinputSmall_msg__12AWg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.textinputSmall_disabled__2H2Wa {
    background-color: #eee;
}
.select_div__9E7ed {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.select_select__1DdxB {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.select_select__1DdxB:hover {
    border: solid 1px black;
}

.select_select__1DdxB:focus {
    border: solid 2px #2196f3;
    padding: 0px 0px 0px 4px;
}

.select_select__1DdxB.select_err__2GlrX {
    border: 1px solid #f44336;
}

.select_select__1DdxB.select_err__2GlrX:focus {
    border: solid 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.select_label__3IhTL {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
    /* border-radius: 10px !important; */
}

.select_select__1DdxB option {
    font-size: 1rem;
}
.selectSmall_div__d1ky4 {
    margin: 0px;
    padding: 0px 6px 0px 0px !important;
    position: relative;
}

.selectSmall_select__3cLyF {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 3px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.selectSmall_select__3cLyF:hover {
    border: solid 1px black;
}

.selectSmall_select__3cLyF:focus {
    border: solid 2px #2196f3;
    padding: 0px 0px 0px 4px;
}

.selectSmall_select__3cLyF.selectSmall_err__OaqTB {
    border: 1px solid #f44336;
}

.selectSmall_select__3cLyF.selectSmall_err__OaqTB:focus {
    border: solid 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.selectSmall_label__2dnMR {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    height: 13px;
    background-color: white;
    font-size: .6rem;
}

.selectSmall_select__3cLyF option {
    font-size: 1rem;
}

.selectSmall_NEW__2v6w7 {
    color: green;
    font-weight: 600;
}

.selectSmall_REQUESTED__1KHkx {
    color: purple;
    font-weight: 600;
}

.selectSmall_CONFIRMED__32fmu {
    color: green;
    font-weight: 600;
}

.selectSmall_DECLINED__TS5AT {
    color: red;
    font-weight: 600;
}

.selectSmall_NVA__3jShC {
    color: orange;
    font-weight: 600;
}
.textarea_div__2N_tS {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.textarea_textarea__Auzh1 {
    position: relative;
    margin: 0px;
    padding: 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    /* -webkit-box-shadow: inset 0px 0px 27px -23px rgba(0,0,0,0.38);
    -moz-box-shadow: inset 0px 0px 27px -23px rgba(0,0,0,0.38);
    box-shadow:  0px 0px 27px -23px rgba(0,0,0,0.38); */
    border-radius: 3px;
    height: 36px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
    resize: none;
}

.textarea_textarea__Auzh1:hover {
    border: solid 1px black;
}

.textarea_textarea__Auzh1:focus {
    border: 2px solid #2196f3;
    padding: 5px;
}

.textarea_label__359tT {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.time_div__3_A0m {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.time_input__2uqsx {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.time_input__2uqsx.time_err__4VUlw {
    border: 1px solid #f44336;
}

.time_input__2uqsx.time_err__4VUlw:hover {
    border: 1px solid #f44336;
}

.time_input__2uqsx.time_err__4VUlw:focus {
    border: 2px solid #f44336;
    padding: 0px 0px 0px 4px;
}

.time_input__2uqsx:hover {
    border: solid 1px black;
}

.time_input__2uqsx:focus {
    border: 2px solid #2196f3;
    padding: 0px 0px 0px 4px;
}

.time_label__24RRH {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.time_msg__3nL5f {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.time_highlight__3A6YO{
    background-color: yellow;
}
.daterange_div__2euUR {
    margin: 0px;
    padding: 0px 0px 0px 0px!important;
    position: relative;
    border-radius: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}



.daterange_input__3NVpB {
    border: #9e9e9e solid 1px!important;
    border-radius: 3px;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px!important;
    background-color: white;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.daterange_div__2euUR > div > div > input {
 border: #9e9e9e solid 1px!important;
}

.daterange_div__2euUR > div > div > div {
    padding-left: 0px!important;
}

.daterange_div__2euUR > div > div {
    padding-left: 0px!important;
}

.daterange_div__2euUR > div {
    padding-right: 12px;
}


.daterange_left__bi7KI{
    border-right: none!important;
}

.daterange_right__1gdC1{
    border-left: none;
}

.daterange_label__3TwvV {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.daterange_msg__AhFT3 {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.daterange_disabled__P1VjM{
    background-color: #eee;
}

.daterange_label__3TwvV.daterange_disabled__P1VjM{
    display: none;
}

.daterange_popperClassName__1K4w3 {
    z-index: 100;
}
.react-datepicker__tab-loop {
    padding: 0px!important;  
  }
.section_section__1fvS_{
    position: relative;
    border-radius: 6px;
    padding: 24px 24px 4px 24px !important;
    /* margin-top: 15px !important; */
    margin-top: 19px;
}

.section_label__3a2Lm{
    margin: 0px;
    padding: 0px;
    font-size:  23px;
    font-style: italic;
    position: absolute;
    z-index: 1;
    background-color: white;
    /* text-shadow: 2px 1px 3px rgb(255, 255, 255); */
    border-radius: 20px 20px 0px 0px;
    padding-left: 5px;
    padding-right: 5px;
    top: -16px;
    left: 12px;
    /* left: 0px; */
}
.switch_label__1taKH {
    padding: 0px !important;
    padding-bottom: 40px !important;
}

.switch_switchStyle__3A1M7 {
    text-align: center;
}
.emailautocomplete_div__3os8T {
    min-height: 20px;
    border-radius: 5px;
    margin-bottom: 10px!important;
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-justify-content: flex-start!important;
            justify-content: flex-start!important;
    -webkit-flex-direction: row!important;
            flex-direction: row!important;
    -webkit-flex-wrap: wrap!important;
            flex-wrap: wrap!important;
    position: relative;
    background-color: white!important;
    border: #9e9e9e solid 1px!important;
}
.emailautocomplete_div__3os8T:hover {
    border: 1px solid black!important;
}

.emailautocomplete_container__2hvlS {
    -webkit-flex: 11 1;
            flex: 11 1;
    max-width: 100%;
}

.emailautocomplete_open__11_pT {
    background-color: white;
    width: calc(100% - 12px);
    border: solid 1px #ddd;
    z-index: 100;
    position: absolute;
}

.emailautocomplete_list__3rlUJ {
    padding: 6px !important;
    width: -webkit-max-content;
    width: max-content;
}

.emailautocomplete_suggestion__11Xb2 {
    font-size: 12px;
    padding: 5px;
    width: -webkit-max-content;
    width: max-content;
}

[role="listbox"] {
    width: -webkit-max-content;
    width: max-content;
}

.emailautocomplete_smallFormList__3zd0B {
    font-size: 12px!important;
}

.emailautocomplete_input__371xf {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border-radius: 3px;
    height: 30px!important;
    outline: none;
    font-size: 1.15rem;
    width: 99%;
    min-width: 130px;
}

.emailautocomplete_smallFormInput__1EDgN {
    font-size: 12px!important;
    height: 20px!important;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-bottom: 0px solid black;
    border-radius: 3px;
    outline: none;
    width: 100%;
}

.emailautocomplete_label__375wi {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.emailautocomplete_msg__ut2Lf {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.emailautocomplete_item__LbgSM {
    font-size: 1.15rem;
    left: 6px;
}

.emailautocomplete_highlighted__o-TTP {
    background-color: #2196f3;
    color: white;
}

.emailautocomplete_add__3IH9k {
    margin: 0px;
    padding: 0px;
    color: #2196f3;
    background-color: white;
    font-size: 32px;
    border-radius: 6px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.emailautocomplete_add__3IH9k:focus {
    background-color: white;
    color: #2196f3;
    border: #2196f3 1px solid;
    margin-left: 3px;
}

.emailautocomplete_emailAutocompleteText__Zi0-t  {
    background-color: red!important
}

.emailautocomplete_span__7HaDK {
    background-color: #33679e!important;
    color: white;
    border-radius: 4px;
    max-height: 23px!important;
    padding: 0px 19px 5px 4px;  
    margin: 3px 3px 3px 3px;
}

.emailautocomplete_invalidEmail__3Besm {
    background-color: #f02603!important;
    color: white;
    border-radius: 4px;
    max-height: 23px!important;
    padding: 0px 19px 5px 4px;  
    margin: 3px 3px 3px 3px;
}

.emailautocomplete_icon__25sgM {
    color:white!important;
    margin-top: 5px;
    margin-left: 2px;
    position:absolute

}
.DatePicker_div__11l6g {
    margin: 0px;
    padding: 0px 0px 0px 0px!important;
    position: relative;
    border-radius: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}



.DatePicker_input__2YMxh {
    border: #9e9e9e solid 1px!important;
    border-radius: 3px;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px!important;
    background-color: white;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.DatePicker_div__11l6g > div > div > input {
 border: #9e9e9e solid 1px!important;
}

.DatePicker_div__11l6g > div > div > div {
    padding-left: 0px!important;
}

.DatePicker_div__11l6g > div > div {
    padding-left: 0px!important;
}

.DatePicker_div__11l6g > div {
    padding-right: 12px;
}


.DatePicker_left__3vJmE{
    border-right: none!important;
}

.DatePicker_right__LJ5Ls{
    border-left: none;
}

.DatePicker_label__3B9Ma {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.DatePicker_msg__dGAX2 {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.DatePicker_disabled__-5dqC{
    background-color: #eee;
}

.DatePicker_label__3B9Ma.DatePicker_disabled__-5dqC{
    display: none;
}

.DatePicker_popperClassName__1V_sQ {
    z-index: 100;
}
.RawSelectSmall_div__2-SxX {
    margin: 0px!important;
    margin-top: 0px!important;
    margin-right: 0px!important;
    padding-right: 0px!important;
    position: relative;
}

.RawSelectSmall_select__3mTsC {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 0px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.RawSelectSmall_select__3mTsC:hover {
    border: solid 1px black;
}

.RawSelectSmall_select__3mTsC:focus {
    outline: 1px solid #2196f3!important;
    box-shadow:0px 0px 0px 1px #2196f3 inset;  
    padding: 0px 0px 0px 0px;
}

.RawSelectSmall_select__3mTsC.RawSelectSmall_err__2MCZ8 {
    border: 1px solid #f44336;
}

.RawSelectSmall_select__3mTsC.RawSelectSmall_err__2MCZ8:focus {
    border: solid 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.RawSelectSmall_label__1mErM {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    height: 13px;
    background-color: white;
    font-size: .6rem;
}

.RawSelectSmall_select__3mTsC option {
    font-size: 1rem;
}

.RawSelectSmall_NEW__wHd8F {
    font-weight: 600;
}

.RawSelectSmall_REQUESTED__PpABp {
    color: purple;
    font-weight: 600;
}

.RawSelectSmall_CONFIRMED__2rraV {
    color: green;
    font-weight: 600;
}

.RawSelectSmall_DECLINED__27DN2 {
    color: red;
    font-weight: 600;
}

.RawSelectSmall_NVA__HhtHr {
    color: orange;
    font-weight: 600;
}
.RawTextInputSmall_div__28T4b {
    margin: 0px;
    padding-right: 0px!important;
    position: relative;
}

.RawTextInputSmall_input__1eQDz {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 2px;
    padding-right: 0px!important;
    background-color: white;
    border: #9e9e9e solid 1px;
    height: 22px;
    outline: none;
    font-size: 12px;
    width: 100%;
}

.RawTextInputSmall_input__1eQDz.RawTextInputSmall_err__3WHdf {
    border: 1px solid #f44336;
}

.RawTextInputSmall_input__1eQDz.RawTextInputSmall_err__3WHdf:hover {
    border: 1px solid #f44336;
}

.RawTextInputSmall_input__1eQDz.RawTextInputSmall_err__3WHdf:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.RawTextInputSmall_input__1eQDz:hover {
    outline: solid 1px black;
}

.RawTextInputSmall_input__1eQDz:focus {
    outline: 1px solid #2196f3!important;
    box-shadow:0px 0px 0px 1px #2196f3 inset;
}

.RawTextInputSmall_label__2Im1U {
    height:16px;
    margin: 0px;
    padding: 0px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.RawTextInputSmall_msg__1wEEw {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.RawTextInputSmall_disabled__3pd_Y{
    background-color: #eee;
}

.sidebar_label__1J-ks {
    height:16px;
    margin: 0px;
    padding: 0px 1px 2px 0px;
    color: #9e9e9e;
    position: absolute;
    left: 8px;
    top: -12px;
    background-color: white;
}
.content{
    padding: 12px;
    overflow-y: auto;
}
textarea {
  min-height: 100px;
}

.party_hidden__3AnfZ {
  display: none;
}

.party_button__3Tgxg {
  margin-left: 0px;
  margin-top: 10px;
}

.party_table__30npG {
  font-size: 1rem;
}

.party_table__30npG > tbody > tr > td > div > input {
  padding: 0px 0px 0px 4px;
  margin: 0px;
  font-size: 1rem;
  height: 30px;
}

.party_table__30npG > tbody > tr > td > div > select {
  padding: 0px 0px 0px 4px;
  margin: 0px;
  font-size: 1rem;
  height: 30px;
}

.party_table__30npG > tbody > tr > td > div {
  padding: 0px !important;
  margin: 0px;
}

.party_containerNumber__1zUjp {
  width: 174px;
}

.party_sealNumber__326Yj {
  width: 174px;
}

.party_weight__3PrF3 {
  width: 100px;
}

.party_unitOfMeasurement__1YfIf {
  width: 100px;
}

.party_count__2rud0 {
  width: 68px;
}

.party_unit__2CFil {
  width: 96px;
}

.party_cubicMeters__tbwE7 {
  width: 100px;
}

.party_hsCode__1YWIh {
  width: 100px;
}

.party_description__x6ojf {
  width: auto;
}

.party_price__jF-8g {
  width: 80px;
}

.party_toast__3yYUG {
  border: 1px black solid;
  background-color: #444;
  border-radius: 4px;
}

.party_successToast__xOweD {
  padding: 6px;
  min-width: 200px;
  background-color: #444;
  color: #4caf50;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  font-size: 1em;
  font-weight: 500;
}

.party_errorToast__fn9lX {
  padding: 6px;
  min-width: 200px;
  background-color: #444;
  color: #f44336;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  font-size: 1em;
  font-weight: 500;
}

.party_shipmentIcon__tJJEh:hover {
  color: #2196f3;
  cursor: pointer;
}

.party_delete__3Kbrr {
  color: #f44336;
  position: relative;
  top: 3px;
}

.party_delete__3Kbrr:hover {
  border-radius: 3px;
  background-color: #f44336;
  color: white;
}

.party_containerSelected__2zyDl {
  background-color: #2196f3;
}

.party_hide__2Z-YN {
  display: none;
}

i {
  cursor: pointer;
}

.party_bookingNumber__1-_JF {
  padding: 0px;
  margin: 0px;
  font-size: 1.2em;
}

/* .sticky{
    position: sticky;
    width: 100%;
    top: 112px;
}

.sticky>th{
    width: 100%;
} */

.party_notes__3RdU9 {
  color: grey;
  font-style: italic;
}

.party_connections__1_B_J:nth-child(even){
  background-color: red;
}

.party_noContactsPlaceholder__14Ciy{
  text-align:center;
  margin-top: 20px;
  letter-spacing: 10px;
}

.party_materializeTabs__1aDPp{
  color: black;
}

.party_deleteIcon__SO3xX{
  text-align:center;
}
.modal {
  overflow: hidden;
}

.modal-action {
  padding-left: 20px;
  margin-top: -30px;
}

.lf-portal-table {
  background-color: white;
  font-weight: 400;
  font-size: 0.9em;
}

.lf-portal-thead {
  background-color: #33679e;
  color: white;
  max-height: 40px;
  line-height: 1;
}

.lf-portal-thead > tr > th {
  position: relative;
  border-radius: 0px;
  text-align: left !important;
  font-size: 1em;
  max-height: 40px;
}

.lf-portal-tbody > tr > td {
  border-radius: 0px;
  text-align: left;
}

.lf-portal-tbody > tr {
  margin: 2px;
  border: 1px solid white;
}

.lf-portal-tbody > tr:hover {
  background-color: #eee;
}

.lf-portal-tbody > tr.selected {
  color: white;
  background-color: #2196f3;
  border: 1px solid white;
}

.lf-portal-tbody > tr.menu-selected {
  color: white;
  background-color: rgb(255, 174, 69);
  border: 1px solid white;
}

.lf-portal-tbody > tr > td {
  margin: 0px;
  padding: 0px;
  padding: 5px 5px 5px 12px;
}

.lf-shipment-context-menu > ul > li:hover {
  background-color: #ddd;
}

.lf-portal-tbody > tr > td.no-margin-no-padding {
  margin: 0px;
  padding: 0px;
  width: 1px !important;
}

.lf-portal-tbody > tr > td.no-margin-no-padding > span.badge {
  margin: 4px;
  padding: 0px;
}

span.badge.new:after {
  content: none;
}

.connection:nth-child(even){
  background-color: #f0f3f7;
  
}

.connection{
  font-size: 18px;
}

.currentParty{
  font-size:18px;
}

.clientConnections{ 
  margin-left: 5px !important;
  width: calc(50% - 5px)!important;
}

.addConnection{
  margin-right: 5px !important;
  width: calc(50% - 5px)!important;
}

.useAlternateSubjectLine {
  margin-top: -4px;
  margin-left: -10px;
  font-size: 12px!important;
}
.card_margin__3W8x8 {
    margin: auto;
    padding: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 100px;
    max-width: 800px;
    border-radius: 20px;
    text-align: center;
}  

.card_label__1FwO2 {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.card_body__37mCR{
    margin-top: 20px;
    padding: 20px;
}

.card_buttons__1_Ji7 {
    color: blue;
    -webkit-justify-content: right;
            justify-content: right;
    margin-right:5px;
    margin-left:5px;
}

.card_edit__3ZCvZ{
    color:green;
    margin-left: 20px;
}

.card_delete__2YLql{
    color:red;
}

.card_cardStyle__3Ew2p{
    min-height: 150px;
    min-width: 250px;
    float: left;
    margin-right: 10px;
    margin-left: 0px;
    margin-bottom: 0px;
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 0px;
    border: 1px whitesmoke dotted;
}

.card_modalCardStyle__1uDhI{
    min-height: 100px;
    min-width: 200px;
    float: left;
    margin-right: 10px;
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
}
.confirm_blue__3ZWcO:hover{
    border: 2px rgb(143, 188, 255) outset;
    cursor: pointer
}

.confirm_red__qoaH8:hover{
    border: 2px rgb(255, 156, 143) outset;
    cursor: pointer;
}

.confirm_modal__3-5q2{
    cursor:default
}
.bookingrequest_table__WMgEH {
    background-color: white;
    font-weight: 400;
    font-size: .9em;
}

.bookingrequest_thead__3OD5R {
    background-color: #33679e;
    color: white;
    max-height: 36px;
    line-height: .5;
}

.bookingrequest_thead__3OD5R>tr>th {
    position: relative;
    border-radius: 0px;
    text-align: center;
    font-size: 1em;
    max-height: 40px;
}

.bookingrequest_thead__3OD5R>tr>th>span {
    position: absolute;
    background-color: white;
    width: 1px;
    height: 22px;
    top: 7px;
    right: -1px;
}

.bookingrequest_tbody__2eQCO>tr>td {
    border-radius: 0px;
    text-align: left;
}

.bookingrequest_tr__2RqQQ {
    margin: 2px;
    border: 1px solid white;
}

.bookingrequest_tbody__2eQCO>tr:hover {
    background-color: #ddd;
}

.bookingrequest_tbody__2eQCO>tr.bookingrequest_selected__31fwI {
    color: white;
    background-color: #2196f3;
    border: 1px solid white;
}

.bookingrequest_tbody__2eQCO>tr.bookingrequest_menu-selected__zGRhq {
    color: white;
    background-color: rgb(255, 174, 69);
    border: 1px solid white;
}

.bookingrequest_tbody__2eQCO>tr>td {
    margin: 0px;
    padding: 0px;
    padding: 5px 5px 5px 12px;
}

.bookingrequest_context-menu__3PBY7>ul>li:hover {
    background-color: #eee;
}

.bookingrequest_tbody__2eQCO>tr>td.bookingrequest_no-margin-no-padding__1p388 {
    margin: 0px;
    padding: 0px;
    width: 1px !important;
}

.bookingrequest_tbody__2eQCO>tr>td.bookingrequest_no-margin-no-padding__1p388>span.bookingrequest_badge__k-Jtt {
    margin: 4px;
    padding: 0px;
}

.bookingrequest_tbody__2eQCO>tr>td>span.bookingrequest_badge__k-Jtt.bookingrequest_new__2nIkM:after {
    content: none;
}
.Carrier_contactSection__3-MGk {
    margin-bottom: 10px !important;
}

.Carrier_addContactSpan__2Stwh {
    cursor: pointer;
    z-index: 999;
}

.Carrier_vesselSection__1GlJ7 {
    padding-bottom: 24px !important;
}

.Carrier_vesselSection__1GlJ7>div>div {
    margin-bottom: 0px;
}

.Carrier_vesselTableBody__1kaSx {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 2 1;
            flex: 2 1;
}

.Carrier_vesselCard__tIBYe {
    padding: 0px;
    max-width: CALC(50% - 12px);
    margin: 5px;
}

.Carrier_vesselCard__tIBYe>div {
    padding: 0px !important;
}

.Carrier_vesselCard__tIBYe>div>i {
    font-size: 22px;
    line-height: 30px;
}

.Carrier_vesselDisplayContainer__1hvQQ {
    max-height: 175px;
    height: 175px;
    overflow-y: scroll;
    padding: 8px;
    border: 1px inset rgb(234, 234, 234);
    border-radius: 3px;
}

.Carrier_carrierOverviewTable__3iRma {
    display: inline-block;
}
/* GENERAL STYLING */
body {
  overflow: hidden !important;
}

.reduxLoader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.reduxLoader>div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 200px;
}

.reduxLoader>div>div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.reduxLoader>div>div>.preloader-wrapper {
  height: 200px;
  width: 200px;
}

#master-content {
  overflow-y: scroll;
  border-top: none !important;
  box-shadow: -2px 3px 5px #aaa !important;
}

nav {
  box-shadow: none;
  border-bottom: 1px solid #e9e9e9;
  background-color: white;
}

.lf-portal-table {
  background-color: white;
  font-weight: 400;
  font-size: 0.9rem;
}

.lf-portal-table-shipment-overview {
  background-color: white;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block !important;
}

.resultsDisplay {
  font-size: 0.8rem;
}

.resultsDisplay:focus,
.resultsDisplay:active,
.exdecResultsDisplay:focus,
.exdecResultsDisplay:active {
  margin-left: -5px;
  border-radius: 5px;
  outline: solid #2196f3 !important;
}

.portOfLoadColumn {
  color: lightgrey;
}

.exdecResultsDisplay {
  background-color: #e9e9e9;
}

@media (min-width: 100px) {
  .resultsDisplay {
    font-size: 0.75rem;
  }
}

@media (max-width: 1000px) {
  .lf-portal-tbody>tr>td>i {
    width: 14px;
  }
}

@media (min-width: 1200px) {
  .resultsDisplay {
    font-size: 0.9rem;
  }
}

.lf-portal-thead {
  background-color: #33679e;
  color: white;
  max-height: 40px;
  line-height: 1;
  min-width: 2000px !important;
  max-width: 10px !important;
}

.lf-portal-thead>tr>th {
  position: relative;
  border-radius: 0px;
  text-align: center;
  font-size: 1em;
  max-height: 40px;
  border-right: 1px solid white;
}

.lf-portal-thead>tr>th>span {
  width: 1px;
  height: 30px;
}

.lf-portal-tbody>tr>td {
  border-radius: 0px;
  text-align: left;
}

.lf-portal-tbody>tr {
  margin: 2px;
  border: 1px solid white;
}

.lf-portal-tbody>tr:hover {
  background-color: #eee;
}

.lf-portal-tbody>tr.selected {
  color: white;
  background-color: #2196f3;
  border: 1px solid white;
}

.lf-portal-tbody>tr.menu-selected,
.week-list-tbody>tr.menu-selected {
  color: white !Important;
  background-color: rgb(255, 174, 69) !important;
  border-left: 1px rgb(255, 174, 69) solid !important;
}

.lf-portal-tbody>tr>td {
  margin: 0px;
  padding: 0px;
  padding: 0px 3px 0px 3px !important;
}

.lf-shipment-context-menu {
  z-index: 99999;
}

.lf-shipment-context-menu>ul>li:hover {
  background-color: #ddd;
}

.lf-portal-tbody>tr>td.no-margin-no-padding {
  margin: 0px;
  width: 1px !important;
}

.lf-portal-tbody>tr>td.no-margin-no-padding>span.badge {
  margin: 4px;
}

.sidebar-collection-header {
  margin-top: -25px !important;
  padding-top: 0px;
}

.sidebar-collection {
  border: none !important;
}

.menu-selected>td {
  border-bottom: white !important;
}

/* BOOKING CREATION STYLING */

.emailBadge {
  color: rgb(0, 0, 0) !important;
  border: 1px black solid;
  font-weight: 700 !important;
}

.badgeCol {
  margin-top: 3px;
  text-align: center;
}

.bccButton {
  margin-bottom: 0px;
}

span.badge.new:after {
  content: none;
}

#logModal {
  width: 60%;
  overflow: auto;
  border-radius: 10px;

}

#emailModal,
#fileUploadEmailModal {
  margin-top: 50px;
  width: 70%;
  padding: 20px;
  padding-bottom: 100px !important;
  height: 70% !important;
  overflow: auto;
  max-height: 100%;
}

#docsDueEmailModal {
  margin-top: 50px;
  width: 70%;
  padding: 20px;
  height: 70% !important;
  overflow: auto;
  max-height: 100%;
}

#excelEmailModal {
  overflow-y: scroll;
}

.email-modal {
  padding-top: 0px !important;
}

.pdfViewer {
  width: 100% !important;
}

.tox-notification {
  display: none !important;
}


#emailButton {
  z-index: 10;
}


.useChangesSwitch {
  z-index: 10;
}


.pdfViewer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: -15px;
  overflow: visible !important;
  position: relative;
}

.pdf-loader {
  height: 100px !important;
  width: 100px !important;
  margin-top: 200px !important;
}

#displayWindow {
  width: 820px;
  height: 980px;
}

.pdfLoading {
  text-align: center;
}

@media(max-width: 1100px) {
  #logModal {
    width: 85%;
  }
}

.changeLogDisplay {
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.changedFromBooking {
  background-color: yellow !important;
}

.changeLogTableHeader {
  text-align: left !important;
}

.s {
  border: 0px black solid;
  padding: 0px;
  margin: 0px;
  border-spacing: 0px !important;
}

.changeLogDisplay>thead {
  background-color: rgb(175, 175, 175);
}

.changeLogDisplay>tbody>tr>td {
  padding: 5px !important;
}

.even {
  background-color: rgb(221, 221, 221) !important;
}

.odd {
  background-color: white !important;
}

.changeLogDisplay>tbody>tr:hover {
  background-color: #e4f5ff !important;
}

.vvSpinner {
  text-align: center;
  padding-top: 40px;
  margin-right: 10px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

span.badge.new:after {
  content: none;
}

.collapsible-body {
  padding-bottom: 0px;
}

.bookingBadge {
  position: absolute;
  margin-left: 25px !important;
  margin-top: 0px;
}

.SIbadge {
  position: absolute;
  left: -80px !important;
  top: 5px;
  z-index: 1;
}

.SIstage {
  position: absolute;
  left: -80px !important;
  top: 10px;
  z-index: 1;
}

.badgeHolder {
  margin-top: 3px;
  margin-right: 45px;
  margin-left: 0px;
  padding-left: 0px !important;
}

.badeHolderStageIcon {
  margin-top: -1px;
  margin-left: 0px;
  padding-left: 0px !important;
}

.ic {
  margin-top: -6px !important;
}

.timeZoneDisplay {
  font-size: 12px;
  margin-left: -10px !important;
  padding-top: 9px !important;
  color: grey;
}

#vessel-add-autocomplete {
  background-color: red;
}

.badge {
  box-sizing: border-box;
  background-color: white !important;
  padding: 0px 0px 20px 0px !important;
  border-radius: 10%;
  font-size: 13px !important;
  font-family: helvetica;
}

#closedBadge {
  color: rgb(100, 100, 100);
  border: 1px rgb(100, 100, 100) solid;
}

#activeBadge {
  color: green;
  border: 1px rgb(3, 168, 3) solid;
}

#cancelBadge {
  color: rgb(255, 0, 0);
  border: 1px rgb(255, 0, 0) solid;
}

#archiveBadge {
  color: black;
  border: 1px black solid;
}

#pendingBadge {
  color: purple;
  border: 1px purple solid;
}

.cancelStamp {
  color: rgb(122, 122, 122);
  letter-spacing: 20px;
  font-size: 150px;
  opacity: 0.3;
  position: absolute;
  top: 35%;
  left: 20%;
  z-index: 1;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

.closedStamp {
  color: rgb(122, 122, 122);
  letter-spacing: 20px;
  font-size: 150px;
  opacity: 0.3;
  position: absolute;
  top: 35%;
  left: 35%;
  z-index: 1;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

.bookingLoader {
  position: absolute;
  left: 55%;
  top: 50%;
}

.names {
  font-size: 16px;
  float: right !important;
  overflow: hidden;
  letter-spacing: -1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.userInfo {
  padding: 1px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  width: 100%;
}

.classTitle {
  font-size: 16px;
  float: left !important;
  overflow: hidden;
}

.internalNotes {
  width: 103% !important;
}

.floatContainer {
  overflow: auto;
  font-family: "Lucida Console";
}

.nameTitle {
  font-size: 16px;
  float: left !important;
  overflow: hidden;
  z-index: 2;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.colon {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-right: 3px;
  float: left;
}

.addNotesHeader {
  max-height: 10px;
  padding-top: 0px;
  padding-bottom: 25px;
  margin-top: -20px;
  margin-bottom: 0px;
  background-color: #f3f3f3 !important;
  border: 2px #f3f3f3 solid !important;
  border-radius: 5px;
}

.addNotesHeader:focus {
  border-radius: 5px;
  border: 2px #2196f3 solid !important;
}

.additionalNotes {
  height: 110px;
  width: 100%;
  padding: 0px;
  margin-bottom: -10px;
  box-shadow: none !important;
  border: none !important;
}

.additionalNotesIcon {
  float: left;
  margin-left: -20px;
}

.createBookingWrapper {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  position: relative;
  overflow: hidden !important;
  background-color: #f3f3f3;
  padding: 6px;
  border-radius: 3px;
  ;
}

.cBar {
  margin-bottom: -10px;
  box-shadow: none !important;
  border: 0px black solid;
  background-color: #f3f3f3 !important;
}

.buttonsRow {
  max-height: 38px;
  margin-bottom: 0px !important;
}

.bookingShipperDropdown {
  background-color: #f3f3f3 !important;
  font-weight: 500 !important;
  border: none !important;
}

.dummy {
  background-color: #f3f3f3;
  font-weight: 500;
  border: none;
  color: #9c27b0 !important;
}

.noValue {
  background-color: white !important;
  border: 1px darkgrey solid !important;
}

#date-warning-modal {
  background-color: darkred;
  color: white;
  border: 2px white solid;
  box-sizing: border-box;
}

.useDisplayNameCheckbox {
  width: 42px !important;
  height: 32px;
  margin-right: -10px;
}

.shipperId {
  margin-right: -10px !important;
}

.checkbox_p__1RAAq:active,
.checkbox_span__eUvpF:active,
.useDisplayNameCheckbox:active,
.useDisplayNameCheckbox:focus {
  border: 0px solid black !important;
}

.hideDisplayName {
  background-color: whitesmoke;
  color: whitesmoke !important;
  border: 0px solid whitesmoke;
}

.hideDisplayName:hover,
.hideDisplayName:focus,
.hideDisplayName:active {
  border: 0px !important;
}

.bookingsEmployee {
  margin-top: 15px;
}

.bookingHeaderInfo {
  text-align: center;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  padding-top: 6px !important;
}

.subscribeCheckbox>div {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.subscribeCheckbox>div>p {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.subscribeCheckbox>div>p>label {
  margin-left: -17px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.target {
  border-bottom: none !important;
}

.vesselVoyageBookingCountRow>div {
  text-align: center;
  font-weight: 600;
}

.flagIconHolder {
  margin-top: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

/* SHIPMENT OVERVIEW STYLING */

.activePage {
  color: white;
  background-color: #33679e;
}

.pagination>li {
  max-height: 22px;
}

.pageLink {
  margin-top: 3px;
}

.pagination {
  border-bottom: 0px !important;
  width: auto !important;
  position: absolute;
  bottom: 10px;
  left: 45%;
}

.resultsCount {
  position: absolute;
  bottom: 10px;
  left: 32%;
  z-index: 1;
}

.overviewCollapsibleBody {
  margin: 0px !important;
  padding-left: 12px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 15px !important;
}

.containerResultsCount {
  position: absolute;
  bottom: 10px;
  left: 15%;
  z-index: 1;
}

.overviewLoader {
  position: absolute;
  left: 50%;
  top: 50%;
}

.overviewCollapsible {
  padding: 10px !important;
}

.search-field {
  margin-bottom: 10px !important;
}

.search-wrapper {
  margin-top: 22px;
}

.searchOpenIcon {
  margin-top: -5px;
}

.dummyPurple {
  color: #9c27b0;
}

.lf-chart-context-menu>ul>li:hover {
  background-color: #ddd;
  cursor: pointer;
}

.lf-chart-context-menu>ul>li {
  border: 1px solid #33679e;
  padding: 10px;
}

#chart-alert {
  position: absolute;
  left: 33%;
  top: 50%;
  font-size: 1.5vw;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
}

.barWrapper {
  position: relative;
}

#submitInitialsBadge,
#bookingsInitialsBadge {
  color: black;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  width: 20px !important;
}

.exdecSwitch {
  padding: 0px !important;
  margin-top: -10px !important;
}

.tooltip:hover {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 15px 0px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptextleft {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 0px 15px;
}

.tooltip:hover .tooltiptextleft {
  visibility: visible;
}

.tooltip .tooltiptextdate {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  left: 50px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 15px 0px;
}

.tooltip:hover .tooltiptextdate {
  visibility: visible;
}

.containerCountTooltip {
  position: relative;
}

.containerCountTooltip .containerCountTooltipText {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  left: 10px;
  bottom: 20px;
  z-index: 4000 !important;
  border-radius: 15px 15px 15px 0px;
}

.containerCountTooltip:hover .containerCountTooltipText {
  visibility: visible;
}

.delayedTooltip {
  position: relative;
}

.delayedTooltip:after {
  opacity: 0;

}

.delayedTooltip .tooltiptext {
  opacity: 0;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  right: 20px;
  bottom: 35px;
  width: -webkit-max-content;
  width: max-content;
  z-index: 4000 !important;
  border-radius: 15px 15px 0px 15px;
  pointer-events: none;
}

.delayedTooltip:hover .tooltiptext {
  opacity: 1;
  transition: opacity 0s linear 1s;
}

.customerDelayedTooltip {
  position: relative;
}

.customerDelayedTooltip:after {
  opacity: 0;

}

.customerDelayedTooltip .tooltiptext {
  opacity: 0;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ;
  position: absolute;
  right: 60px;
  bottom: 10px;
  width: -webkit-max-content;
  width: max-content;
  z-index: 4000 !important;
  border-radius: 15px 15px 0px 15px;
  pointer-events: none;
}

.customerDelayedTooltip:hover .tooltiptext {
  opacity: 1;
  transition: opacity 0s linear 1s;
}

#hiddenTabs {
  visibility: hidden;
  height: 35px;
}

.customerName {
  font-weight: 800;
}

#hiddenJsonUploadButton {
  display: none;
}


/* SHIPMENT INSTRUCTIONS STYLING */
#alias-modal {
  text-align: center;
  width: 25%;
  overflow: auto;
  overflow-x: hidden;
}

.aliasCards {
  cursor: pointer;
  max-height: 50px;
  min-width: 100%;
  float: left;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 5px;
  border-radius: 10px;
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
}

#newAlias {
  margin-top: 5px;
  max-width: 90%;
  margin-left: 10%;
}

#addAliasIcon {
  float: left;
  margin-top: 10px;
  color: green;
}

#deleteAliasIcon {
  float: right;
  margin-top: -23px !important;
}

#deleteAliasIcon:active {
  color: red;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.infoIcon {
  width: 15px;
}

.infoIcon:hover {
  color: rgb(139, 97, 255);
}

.noAliasPlaceholder {
  margin-bottom: 25px !important;
}

#alias-modal>.modal-content>h4 {
  text-align: center !important;
}


.aliasDisplay,
.aliasDisplay:hover {
  background-color: rgb(255, 255, 255);
  border: 0px;
  font-weight: 500;
  font-size: 15px;
  font-style: italic;
}

.noAddressCard {
  text-align: center;
  font-size: 20px;
  padding: 15px;
  margin-top: 50px;
}

.addressCardHolder {
  text-align: center;
  border-radius: 20px;
  margin-right: 30px;
}

#address-modal>.modal-content {
  text-align: center;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: -10px !important;
}

#addressModalButton {
  margin-right: 10px;
  margin-bottom: -10px !important;
}

.addressOption {
  height: 150px;
  width: 250px;
}

.cutOffs {
  height: 18px;
  font-size: 12px;
}

.bookingDetails {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background-color: #f3f3f3 !important;
}

.infoIcon {
  width: 15px;
  margin-left: 4px;
}

.checkBox {
  margin: 0px !important;
}

.siCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after,
.printCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #0b78c4 !important;
  background-color: #0b78c4 !important;
}

.dummyCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #9c27b0 !important;
  background-color: #9c27b0 !important;
}

.exporterCheckBox {
  margin-top: 15px !important;
}

.consigneeCheckBox {
  margin-top: -5px !important;
}

.dummySICheckbox {
  margin-left: 85px;
  margin-top: -5px;
}

.useAliasCheckbox {
  max-width: 100px;
}

.printSiCheckbox,
.printSiCheckbox:active,
.printSiCheckbox:hover,
.printSiCheckbox:focus,
.printSiCheckbox:checked,
.printSiCheckbox:target,
.printSiCheckbox::selection {
  width: 200px !important;
}

.conIsSame {
  margin-right: 10px !important;
  margin-top: -35px !important;
}

.ckScootLeft {
  margin-left: -10px !important;
}

.rowDropper {
  margin-top: 15px !important;
}

.bumpRight {
  margin-left: 10px !important;
}

.rowMarginRemove {
  margin: 0px !important;
  margin-bottom: 5px !important;
}

.noMargin {
  margin: 0px !important;
}

.customMarginRemove {
  margin-bottom: 0px !important;
}

.exportTuck {
  margin-top: -10px !important;
}

.weightTotals {
  margin-top: -20px !important;
}

.misMatched,
.misMatchedPort {
  color: red !important;
  font-weight: 600;
}

#exporterAddress,
#addressArea {
  margin: 0px;
  margin-bottom: -20px;
}

.siWrapper {
  border-radius: 3px;
  background-color: white;
  margin-top: 10px;
}

#exportInstructions {
  min-height: 70px !important;
  width: calc(100% + 3px);
}

#internalNotes {
  margin-left: 0%;
  width: calc(100% + 5px);
  min-height: 70px !important;
}

#exportInstructionsLabel {
  font-size: .8rem;
}

.supplierSameAsShipper {
  margin-top: -35px !important;
  margin-right: -10px !important;
}

.cooMarginTop {
  margin-top: 20px !important;
  margin-left: 10px !important;
  background-color: white;
}

.partyCard {
  padding-left: 10px !important;
  padding-right: 0px !important;
  background-color: white;
  min-width: 100%;
  margin-bottom: 0px;
}

.supplierAndNotifyParty {
  margin-bottom: 0px;
}

.createdBy {
  margin-top: 0px !important;
  padding: 5px !important;
  margin-bottom: 10px !important;
  min-width: 102%;
  margin-left: -8px !important;
  max-height: 90px;
  background-color: #f3f3f3 !important;
}

.row>.createdBy {
  padding-top: 0px !important;
}

.descriptionOfGoods {
  padding-top: 0px !important;
  background-color: white;
}

.addressButton {
  margin: 0px;
}

.buttonDrop {
  margin-top: 20px;
}

.buttonHalfDrop {
  margin-top: 6px;
  margin-bottom: 0px;
}

.disabledRow {
  background-color: #f5f5f5 !important;
  border: none;
}

.addressBox {
  font-size: 1em !important;
}

.specificButtonDrop {
  margin-top: -15px !important;
}

.exporterAddressButton {
  margin-top: 20px;
  margin-bottom: 0px;
}

.consigneeAddressButton {
  margin-bottom: 0px !important;
}

.consigneeAddressButton>button,
.exporterAddressButton>button,
.supplierAddressButton>button,
.notifyAddressButton>button {
  box-shadow: 0 2px 2px #ccc;
}

.aliasButton {
  margin-bottom: 100px !important;
}


.onlyPartyButtons {
  border: none !important;
}

.siAddressButtonsWidth {
  width: 109.75px !important;
}

.siAliasButtonsWidth {
  width: 70.94px !important;
}

.siAliasButtonsWidth>button {
  box-shadow: 0 2px 2px #ccc !important;
}

.containerRow {
  background-color: whitesmoke;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100% !important;
  margin-bottom: 0px;
}

.addDeleteContainer {
  margin-top: -35px;
  margin-bottom: -10px;
}

.containerTableRow {
  margin-top: -20px;
}

.date-0 {
  margin-left: 1px !important;
}

.date-1,
.date-2 {
  margin-left: -2px !important;
}

.hideButton>button {
  box-shadow: none !important;
  color: darkgray !important;
  pointer-events: none;
  border: 1px solid darkgray;
  background-color: whitesmoke;
}

.resetButton {
  margin-left: -145px !important;
  margin-top: -5px !important;
  height: 25px;
}

#print-modal>.modal-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

#print-modal {
  overflow-y: auto;
}

.lf-address-list {
  padding-left: 10px;
  padding-top: 5px;
  border-radius: 10px;
  margin-right: 10px;
  float: left;
  min-width: 200px;
  min-height: 100px;
  cursor: pointer;
}

.lf-address-selected {
  border: 2px solid rgb(58, 92, 205) !important;
}

.fileCountSpinner {
  height: 15px !important;
  width: 15px !important;
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: -3px !important;
}


/* CONTAINERS STYLING */
.containerButton,
.containerCountInput {
  margin: 0px !important;
  margin-bottom: 5px !important;
}

.containerCountInput {
  width: 100% !important;
}

.byContainerTotalInput {
  width: 100%;
  color: black;
}

.containerButton {
  width: 100% !important;
}

.deleteButton {
  margin-left: 4px !important;
}

.customTotal {
  width: 100% !important;
}

.placeholder {
  display: none !important;
  color: #dfdfdf !important;
}

.originalNameHeader {
  margin-top: 0px;
}

.docOptions {
  height: 10px;
}

.csvDownload,
.copyContainers {
  padding: 0px;
  margin: 0px;
}

.csvButton {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: flex-end;
          flex-flow: flex-end;
  float: right;

}

.downloadCsvButton,
.copyContainersButton {
  margin: 0px;
  padding: 0px !important;
}

.copyContainersButton {
  display: none;
}

.buttonSection {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.invisibleButtonSection {
  width: 36.22px;
  height: 30px;
  margin-right: 10px !important;
  ;
}

.invisibleButtonSection:hover>.copyContainersButton {
  display: block;
  margin-right: 10px !important;
}

.exportButtonRow {
  position: relative;
  border-bottom-color: #f5f5f5;
}

/* INVOICE */

.commissionSection {
  border: 1px black solid;
}

.invoicePaid {
  color: green;
}

.checkInRedux {
  color: blue;
}

.stagesNotesInvoice {
  border: 1px solid black;
  padding: 10px;
  border-radius: 3px;
  font-weight: 500;
}

/* TABS CONTAINER */

#marksAndNumbers,
#groupedWeight,
#unitPrice,
#amount,
#termsOfPrice\:0,
#termsOfDelivery\:0,
#countryOfOrigin\:0,
#preferenceCode\:0,
#hsCode {
  background-color: white;
}

/* DOCS OVERVIEW */

.overviewCheckbox {
  width: 60px !important;
}

.stageInitials {
  font-size: 16px;
  margin-top: 5px !important;
  display: -webkit-flex !important;
  display: flex !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

#initialsBadge {
  border: 1px white solid;
  margin-bottom: 5px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

#closeoutInitialsBadge {
  color: black;
  border: 1px white solid;
  margin-bottom: 5px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.proofed {
  color: #f57c00 !important;
}

.notComplete {
  color: black !important;
}

.bookingIcon:hover {
  color: #2196f3;
  cursor: pointer;
}

.badgeAndIconColumn {
  min-width: 20px !important;
  width: 10px;
}

.iconColumn {
  padding-top: 3px;
}

.contextMenuSelect {
  font-size: 10px !important;
  height: 20px;
  margin-left: 5px;
}

.assignButton {
  margin: 0px;
  padding: 5px;
  border-radius: 5px;
  border: 1px rgb(80, 80, 112) solid;
  box-shadow: 2px 2px black;
  cursor: pointer;
  color: rgb(0, 0, 0);
  padding-top: 2px;
  padding-bottom: 2px;
}

.assignButton:hover {
  color: white;
  background-color: rgb(0, 0, 0);
}

.contextList {
  text-align: center !important;
}

.cancelOption {
  text-align: center !important;
  background-color: white !important;
  width: 20px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 0px;
  padding: 0px !important;
  line-height: 15px !important;
  color: black !important;
  border-radius: 10px;
}

.cancelIcon {
  color: red;
}

.cancelWords {
  text-align: center !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.docResults:nth-child(odd) {
  background-color: rgb(213, 213, 213);
}

#overviewStagesModal,
#bookingAndSiStagesWindow {
  margin-top: -50px !important;
  background-color: rgb(255, 255, 210);
  width: 70vw;
  height: auto !important;
  max-height: calc(100vh - 20px) !important;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
  overflow-x: hidden;
  color: black;
  z-index: 100;
  overflow-y: auto;
}

.closeModalIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.stickyNoteButton {
  padding-left: 20px;
  margin-top: 10px !important;
}

.stickyNoteButtonTab {
  border: 1px solid black;
  height: 48px !important;
  margin-top: 0px !important;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
}

.stickyNoteButtonClicked {
  box-shadow: inset 0 0 10px #000000;
}

.stickyNoteButtonUnClicked {
  background-color: white;
}

.stickyNoteButtonClickedYellow {
  box-shadow: inset 0 0 10px #000000;
  background-color: #ffffd2;
}

.stickyNoteButtonUnClickedYellow {
  background-color: #ffffd2;
}

.stagesWindowSwitch {
  margin-top: -10px !important;
}

.flaggedForRevisionCheckbox[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid red;
  background-color: red;
}

.stagesCheckbox,
.stagesCheckbox:hover,
.stagesCheckbox:active,
.stagesCheckbox:focus,
.stagesCheckbox:target,
.stagesCheckbox::selection {
  color: black;
  font-size: 20px !important;
}

.stagesWindowHeader {
  text-align: center;
  font-size: 18px;
  margin-top: -10px;
  margin-bottom: -10px;
  overflow: hidden;
}

.stickyNoteRow {
  margin-top: -10px;
}

.slimRow {
  margin-top: -15px;
  margin-bottom: 5px;
}

.invisibleButton {
  height: 38px !important;
  margin-bottom: 10px !important;
}

.stagesWindowChecks {
  border-bottom: 1px solid black;
  padding-bottom: 15px !important;
  padding-top: 0px;
}

.stagesWindow>.col {
  padding: 30px;
  padding-bottom: 0px;
}

.lastNotesRow {
  padding: 10px;
  border: 1px solid black;
}

.lastInnerNotesRow {
  margin-top: -10px;
  margin-bottom: 0px;
}

.dateAndName {
  margin-top: 10px !important;
  font-weight: 700;
}

.generateButtonIcon>button {
  background-color: black !important;
  width: 1px !important;
  height: 1px !important;
  margin-right: -100px !important;
  margin-left: -50px !important;
}

.generateButton>button {
  width: 85px !important;
}

/* PARTY PAGES */

.tabs .tab a {
  color: #000;
}

.tabs {
  position: relative !important;
}

.filesTab {
  position: absolute;
  left: 180px !important;
  font-weight: 400;
}

.bookingAndSiTabs>li {
  border-right: 1px solid rgb(208, 208, 208);
}

.siAndBookingTabs>li {
  border-left: 1px solid rgb(208, 208, 208);
}

.bookingAndSiTabs,
.siAndBookingTabs {
  margin-bottom: 0px !important;
  border-bottom: 1px gray solid;
  padding-bottom: 1px;
}

.bookingAndSiTabs>div>ul>li,
.siAndBookingTabs>div>ul>li {
  border-radius: 5px !important;
  border-left: 1px black solid;
  border-top: 1px black solid;
  margin-top: 0px !important;
  z-index: 999 !important;
}

.bookingAndSiTabs>div>ul>li:last-child,
.siAndBookingTabs>div>ul>li:last-child {
  border-right: 1px black solid;
}

.bookingAndSiTabs>div>ul>li>a,
.siAndBookingTabs>div>ul>li>a {
  margin-top: -6px !important;
}

@media only screen and (max-width: 1000px) {

  .bookingAndSiTabs,
  .siAndBookingTabs {
    border-bottom: 0px gray solid;
  }

  .bookingTabs,
  .siTabs {
    border-bottom: 1px gray solid !important;
  }
}

.tabs .tab a:hover {
  background-color: #eee;
  color: #4890dd;
}

.tabs .tab.disabled a,
.tabs .tab.disabled a:hover {
  color: rgba(102, 147, 153, 0.7);
}

.tabs .tab a.active {
  color: #33679e;
}

.tabs .indicator {
  background-color: #33679e;
}

.contactsTableHead {
  background-color: #f3f3f3 !important;
}

.contactsTable {
  line-height: 0px;
}

.contactsTable:nth-child(even) {
  background-color: #f3f3f3 !important;
}

.currentParty,
.connection>td {
  font-size: 15px !important;
}

.addConnection,
.clientConnections {
  padding-top: 20px !important;
}

.addConnectionTable {
  margin-top: 20px !important;
}

.addConnectionTable>thead>tr,
.addConnectionTable>tbody>.bottomRow {
  border: none;
}

/* CUSTOMER OVERVIEW */

.customerDocDate {
  color: green;
}

.firstCargoDate>td {
  border-top: 2px dashed grey !important;
}

.customer-portal-table {
  border-collapse: collapse !important;
  border-spacing: 0;
  text-align: left;
  display: inline-block;
}

/* .customer-portal-tbody>tr{  */
/* padding: 10px!important;
  padding-left: 100px!important;
  border-bottom: 2px dotted grey!important;  */
/* }  */

/* .customer-portal-table>thead>tr>th{
  margin-left: 10px!important;
  margin-bottom: 20px!important;
} */

/* SHIPMENT OVERVIEW */


.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.selectable {
  user-select: none;
  -moz-user-select: text;
  -webkit-user-select: text;
}


.docsDueTableRow:hover>.hiddenMenu>.hiddenMenuTextArea>.hideNotes {
  display: none !important;
}

.hiddenMenuTextArea {
  display: none !important;
}

.hiddenMenu {
  height: 100% !important;
  position: relative !important;
}

.hiddenMenuButtons {
  display: none !important;
  overflow: visible;
  margin-left: 10px !important;
}

.docsDueTableRow>td {
  min-height: 24px !important;
}

.docsDueTableRow:hover>.hideOnHover {
  color: #eee;
}

.menu-selected:hover>.hideOnHover {
  color: rgb(255, 174, 69) !important;
}


.docsDueTableRow:hover>.hiddenMenu>.hiddenMenuTextArea {
  display: block !important;
  width: 500px !important;
  position: absolute;
  top: -50px;
  right: 125px;
  z-index: 100;
}

.docsDueTableRow:hover>.hiddenMenu>.hiddenMenuButtons {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  height: inherit !important;
  margin-left: 10px !important;
  position: absolute;
  top: 0px;
  right: 10px;
}

.lf-portal-tbody>tr.menu-selected>.hiddenMenu>.hiddenMenuButtons {
  color: white;
  background-color: rgb(255, 174, 69) !important;
}

.exdecHiddenButtonStyle {
  height: 70% !important;
  width: 30px;
}

.lastSentTime {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-weight: 700;
  font-size: 14px;
  width: -webkit-max-content;
  width: max-content;
}

@media only screen and (max-width: 900px) {
  .lastSentTime {
    font-size: 10px;
    width: 50px;
    margin-right: -25px;
  }
}

.heightParent {
  height: inherit;
}

.hiddenMenuButton {
  height: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.siNotesButtonIcon {
  text-align: center !important;
  margin-right: 10px;
}

.docsDueInternalNotes {
  min-height: 55px !important;
  max-height: 55px !important;
  background-color: #3f3f3f !important;
  color: white !important;
  border-radius: 10px 10px 0px 10px;
}

.docsDueInternalNotesLabel {
  background-color: #3f3f3f !important;
  color: white !important;
  border-radius: 5px !important;
}

.chartButtonIcon {
  margin: 0px !important;
  padding: 0px !important;
}

.chartButton {
  margin-bottom: 12px;
  padding-right: 20px !important;
}

.chartButton>button {
  padding-left: 3px;
  padding-right: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.chartButtonPressed>button,
.chartButtonPressed:active>button {
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: black !important;
  background: #e4e4e4 !important;
  box-shadow: inset 0px 0px 7px #979797 !important;
  -mox-box-shadow: inset 0px 0px 5px #727272 !important;
  -webkit-box-shadow: inset 0px 0px 5px #424141 !important;
  outline: none !important;
  border: 2px inset rgb(175, 175, 175) !important;
}

.dateSearchButtons>button {
  padding-right: 10px;
}

.dateSearchButtonsArrowBack>button {
  padding-right: 5px;
  padding-left: 11px;
}

.dateSearchButtonsRight {
  padding-right: 0px;
}

.dateSearchButtons,
.dateSearchButtonsArrowBack {
  padding: 0px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.dateSearchButtons>button,
.dateSearchButtonsArrowBack>button {
  padding: 0px !important;
  text-align: center !important;
  max-width: 20px !important;
}

.dateSearchButtons>button>i,
.dateSearchButtonsArrowBack>button>i {
  text-align: center !important;
  padding-left: 0px !important;
  margin: 0px !important;
  width: 15px;
}

.dateSearchButtonContainer {
  padding-left: 0px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  margin-bottom: 0px !important;
  height: 30px !important;
}


@media screen and (max-width: 1500px) {

  .dateSearchButtons>button,
  .dateSearchButtonsArrowBack>button {
    max-width: 50px !important;
  }

  .dateSearchButtons,
  .dateSearchButtonsArrowBack {
    padding: 0px !important;
    max-width: 200px;
  }
}

.overviewFilterButtonsContainer {
  margin: 0px;
  padding: 0px !important;
  padding-right: 12px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.overviewFilterButtonsContainer>div {
  padding: 0px !important;
  margin-bottom: 0px;
}

.overviewFilterButtonsContainer>div>button>i {
  margin-left: -6px !important;
}

.dateBordersLeft {
  border-left: 1px solid black;
}

.dateBordersRight {
  border-right: 1px solid black;
}

/* REPORTS PAGE */

#commissionReportTable {
  font-size: 12px;
}

#commissionReportTable>thead>tr {
  background-color: #33679e;
  color: white;
}

#commissionReportTable>thead>tr>th {
  text-align: left;
}

#commissionReportTable>tbody>tr:nth-child(even) {
  background-color: rgb(226, 226, 226);
}

.commissionAverage {
  font-weight: 700;
}

.activeTab,
.activeTab>a,
.activeTab:active,
.activeTab:focus {
  background-color: #33679e !important;
  color: white !important;
  border-radius: 14px 14px 0px 0px;
}

.activeBookingTab,
.activeBookingTab>a,
.activeBookingTab:active,
.activeBookingTab:focus,
.activeSiTab,
.activeSiTab>a,
.activeSiTab:active,
.activeSiTab:focus,
.activeUploadTab,
.activeUploadTab>a,
.activeUploadTab:active,
.activeUploadTab:focus {
  background-color: #33679e !important;
  color: white !important;
  border-radius: 14px 14px 0px 0px;
}

.activeBookingTabPrinted,
.activeBookingTabPrinted:active,
.activeBookingTabPrinted:focus,
.activeSiTabPrinted,
.activeSiTabPrinted:active,
.activeSiTabPrinted:focus,
.activeUploadTabPrinted,
.activeUploadTabPrinted:active,
.activeUploadTabPrinted:focus,
.activeUploadTabPrinted:focus-visible {
  background-color: #0b9148 !important;
  border-radius: 14px 14px 0px 0px;
}

.activeBookingTabPrinted>a,
.activeSiTabPrinted>a,
.activeUploadTabPrinted>a {
  color: white !important;
}

.inactiveBookingTabPrinted,
.inactiveBookingTabPrinted:active,
.inactiveBookingTabPrinted:focus,
.inactiveSiTabPrinted,
.inactiveSiTabPrinted:active,
.inactiveSiTabPrinted:focus .inactiveUploadTabPrinted,
.inactiveUploadTabPrinted:active,
.inactiveUploadTabPrinted:focus {
  background-color: white !important;
  font-weight: 600;
  border-radius: 14px 14px 0px 0px;
}

.inactiveBookingTabPrinted>a,
.inactiveSiTabPrinted>a,
.inactiveUploadTabPrinted>a {
  font-weight: 600;
  color: #0b9148 !important;
}

.activeUploadTabUseNotes>a {
  color: black !important;

}

.flagIconHolder {
  margin-top: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.vesselVoyageBookingCountRow>div {
  text-align: center;
  font-weight: 600;
}

.bookingTabs .indicator,
.siTabs .indicator {
  display: none;
}

/* CONTRACTS */

.progress {
  height: 15px !important;
  text-align: right;
  padding-right: 5px !important;
  color: white;
  z-index: 100;

  background-color: #aecef0;
}

.determinate {
  background-color: #33679e !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
  margin-top: -4px;
  vertical-align: text-top !important;
}

.stepStatus {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-top: 15px !important;
}

.lfManagedDetails {
  margin-bottom: 12px;
}

.contractsSidebarTest {
  background-color: red;
  position: absolute;
  left: -30px;
  z-index: 4000 !important;
}

.contractEditHeader {
  height: 190px;
  margin: 0px;
  padding: 0px;
  padding-top: 25px;
  font-size: 14px;
}

.originDropdownIcon:focus {
  border-radius: 5px;
  border: 2px black solid !important;
}

.lastActiveFreetime {
  background-color: #d5eaff;
}

/* FILE UPLOAD */

.hideTab {
  display: none !important;
}

.moverModal {
  width: 50%;
  height: -webkit-max-content;
  height: max-content;
}

.moverModal>.modal-content>.lineBreak {
  border: 1px solid rgb(201, 201, 201) !important;
  margin-bottom: 10px;
}

.moverModal>.modal-content {
  margin-bottom: 0px !important;
}

.noFilePlaceholder {
  height: 200px;
  font-style: italic;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  ;
}

#awsPdfViewer>.modal-content>h5 {
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
}

#awsPdfViewer>.modal-content>.row>.col>span {
  font-weight: 600 !important;
}

#awsPdfViewerDisplayWindow {
  width: 585px;
  height: 500px;
}

#fileViewerModal>.modal-content {
  height: 700px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

#fileUpload {
  display: block
}

#fileUploadContainer {
  height: 82vh;
  overflow: hidden !important;
  display: block;
  width: 100%;
  position: relative !important;
}

.fileListSection {
  height: 72vh;
  overflow-y: scroll !important;
  padding-top: 10px;
}

#fileTable>thead>tr>th {
  padding-top: 0px !important;
}

#fileTable>tbody>tr>td {
  padding: 0px !important;
}

#fileTable>tbody>tr>td>div>div>p>label {
  padding-left: 10px !important;
}

#fileTable>tbody>tr {
  padding: 0px 0px 0px 8px !important;
}

#fileTable>tbody>tr:hover {
  background-color: rgb(45, 40, 40);
  color: white;
  margin-bottom: 100px !important
}

.activeFileRow:hover {
  background-color: black;
  color: white;
}

.hiddenFileRow {
  color: gray !important;
  background-color: rgb(234, 231, 231) !important;
}

.hiddenFileRow:hover {
  background-color: rgb(111, 111, 111) !important;
  color: white !important;
}

.hiddenRowContainer {
  position: relative;
  height: 30px;
  background-color: lightgray;
  color: black;
  width: 400px !important;
}

.hiddenRowMessage {
  margin-top: 5px;
  position: absolute;
  left: 50%;
}

.oldFileRow:hover {
  background-color: rgb(196, 196, 196) !important;
  color: black !important;
}

#uploadButton {
  display: none;
}

.hiddenCollapsible {
  padding-bottom: 10px;
  text-align: left !important;
  padding-left: 25px;
  padding-top: 10px;
}

.hiddenCollapsible>span {
  padding-top: 20px !important;
}

.dragDetector {
  width: 100vw;
  height: 100vh;
  opacity: 0.1 !important;
  position: absolute;
}

.fileUploadIconHolder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

}

.spinnerHolder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.waitingForDrop {
  background-color: rgb(222, 222, 222) !important;
  opacity: .8;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgb(255, 252, 255) 50%, transparent 50%), linear-gradient(90deg, rgb(255, 255, 255) 50%, transparent 50%), linear-gradient(0deg, rgb(255, 255, 255) 50%, transparent 50%), linear-gradient(0deg, rgb(255, 255, 255) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  -webkit-animation: border-dance 8s infinite linear;
          animation: border-dance 8s infinite linear;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 10;
  top: 0px;
  left: 0px;
}

.notWaitingForDrop>div>i,
.notWaitingForDrop>div>span {
  display: none;
  color: red !important;
}


.waitingForDrop * {
  user-select: none !important;
  -webkit-user-select: none;
  pointer-events: none !important;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
  z-index: 0 !important;
}

.notWaitingForDrop {
  height: 100vh;
  opacity: 1 !important;
  position: absolute;
  pointer-events: none !important;
}

.arrowIcon {
  position: absolute;
  text-align: left;
  left: 0px;
  top: 8px;
}


@-webkit-keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }

  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}


@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }

  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}


.newFile {
  background-color: white;
  -webkit-animation-name: firework;
          animation-name: firework;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes firework {
  0% {
    background-color: #33679e;
    color: white;
  }

  15% {
    background-color: #457ab3;
    color: #eff4f9;
  }

  25% {
    background-color: #5287bf;
    color: #d9e5f1;
  }

  35% {
    background-color: #669bd3;
    color: #c3d8ef;
  }

  45% {
    background-color: #76a6d8;
    color: #a8c8eb;
  }

  55% {
    background-color: #8fb6df;
    color: #76a6d8
  }

  65% {
    background-color: #a8c8eb;
    color: #5287bf;
  }

  75% {
    background-color: #c3d8ef;
    color: #457ab3;
  }

  85% {
    background-color: #d9e5f1;
    color: #33679e;
  }

  95% {
    background-color: #eff4f9;
    color: black;
  }

  100% {
    background-color: #ffffff;
    color: black;
  }
}

@keyframes firework {
  0% {
    background-color: #33679e;
    color: white;
  }

  15% {
    background-color: #457ab3;
    color: #eff4f9;
  }

  25% {
    background-color: #5287bf;
    color: #d9e5f1;
  }

  35% {
    background-color: #669bd3;
    color: #c3d8ef;
  }

  45% {
    background-color: #76a6d8;
    color: #a8c8eb;
  }

  55% {
    background-color: #8fb6df;
    color: #76a6d8
  }

  65% {
    background-color: #a8c8eb;
    color: #5287bf;
  }

  75% {
    background-color: #c3d8ef;
    color: #457ab3;
  }

  85% {
    background-color: #d9e5f1;
    color: #33679e;
  }

  95% {
    background-color: #eff4f9;
    color: black;
  }

  100% {
    background-color: #ffffff;
    color: black;
  }
}

.file-upload-context-menu>ul>li {
  color: black;
  padding: 10px !important;
  cursor: pointer;
}

.file-upload-context-menu,
.file-upload-context-menu>ul {
  z-index: 1000 !important;
}

.file-upload-context-menu>ul>li:hover {
  background-color: rgb(235, 234, 234);
}

.attachmentListIcons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.attachmentList {
  border: none !important;
}

.attachmentListItem {
  border: 1px solid darkblue;
  background-color: rgb(188, 188, 188);
  color: white;
  border-radius: 5px;
  margin-bottom: 5px;
}

.fileSizeWarning {
  font-size: 20px;
  color: rgb(132, 78, 78);
}

.uploadQuote {
  font-size: 18px;
  color: black;
  text-align: center;
  font-style: italic;
}

.uploadScreenTip {
  position: fixed;
  bottom: 100px;
  left: 50%;
  margin-left: -150px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  ;
  -webkit-justify-content: center !important;
  ;
          justify-content: center !important;
  font-style: italic;
}

.quoteContainer {
  margin-top: 20px;
}

/* USERS OVERVIEW */

.user-portal-table {
  font-weight: 400;
  font-size: 0.9rem;
  color: white;
}

.user-portal-table>tbody>tr {
  color: black;
  font-size: 0.8rem;
}

.user-portal-thead {
  background-color: #33679e;
}

.userEditCheckBox>div>p>label,
.userEditCheckBox>div>p,
.userEditCheckbox>div,
.userEditCheckbox {
  width: 100%;
}

.userEditCheckbox>div,
.userEditCheckbox>div:hover,
.userEditCheckbox>div:active {
  width: 100%
}

/* VESSEL SCHEDULE */

.sche_field_name {
  background-color: #0577B3;
  color: white;
}

.sche_field_odd {
  background-color: #EAEAEA;
  border-top: 1px solid #bdbdbd;
}

.sche_field_even {
  background-color: #C9ECFE;
}
.shipments_hidden__VIFd9 {
    display: none;
}

.shipments_table__39lgb {
    font-size: 1rem;
}

.shipments_table__39lgb>tbody>tr>td>div>input {
    padding: 0px 0px 0px 4px;
    margin: 0px;
    font-size: 1rem;
    height: 30px;
}

.shipments_table__39lgb>tbody>tr>td>div>select {
    padding: 0px 0px 0px 4px;
    margin: 0px;
    font-size: 1rem;
    height: 30px;
}

.shipments_table__39lgb>tbody>tr>td>div {
    padding: 0px !important;
    margin: 0px;
}

.shipments_toast__1Ojrs {
    border: 1px black solid;
    background-color: #444;
    border-radius: 4px;
}

.shipments_successToast__3_20k {
    padding: 6px;
    min-width: 200px;
    background-color: #444;
    color: #4caf50;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    font-size: 1em;
    font-weight: 500;
}

.shipments_errorToast__LvcDY {
    padding: 6px;
    min-width: 200px;
    background-color: #444;
    color: #f44336 ;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    font-size: 1em;
    font-weight: 500;
}

.shipments_shipmentIcon__xlWuY:hover{
    color: #2196f3;
    cursor: pointer;
}

.shipments_delete__-TSFi{
    color: #f44336;
    position: relative;
    top: 3px;
}

.shipments_delete__-TSFi:hover{
    border-radius: 3px;
    background-color: #f44336;
    color: white;
}

.shipments_containerSelected__2Lyv2{
    background-color: #0b78c4;
}

.shipments_containerSelected__2Lyv2:hover{
    background-color: #2196f3;    
}

.shipments_hide__31TCc{
    display:none;
}

i{
    cursor: pointer;
}

.shipments_bookingNumber__ybLn4{
    padding: 0px;
    margin: 0px;
    font-size: 1.2em;
}

.shipments_containerNumber__1qUU6 {
    width: 174px;
}

.shipments_sealNumber__2YB7m {
    width: 174px;
}

.shipments_weight__1CZby {
    width: 100px;
}

.shipments_unitOfMeasurement__1-ep1 {
    width: 100px;
}

.shipments_count__2kGHI {
    width: 68px;
}

.shipments_unit__1x79y {
    width: 96px;
}

.shipments_cubicMeters__cODlt {
    width: 70px;
}

.shipments_hsCode__CUSbT {
    width: 100px;
}

.shipments_description__2RqZX {
    width: auto;
}

.shipments_price__2lO-s {
    width: 80px;
}


.shipments_transparentSpinner__R09PB {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    z-index: 500;
    background-color: rgb(120, 120, 120, .2);
}

/* .sticky{
    position: sticky;
    width: 100%;
    top: 112px;
}

.sticky>th{
    width: 100%;
} */

.shipments_processingLoadingSpinner__olgbl { 
    margin-top: 20%;
    /* height: 300px!important;
    width: 300px!important; */
}

.shipments_loadingLabel__3SchD {
    margin-top: 20%;
}
.EmailForm_emailFormButtonRow__k--gK {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.FilterContainer_container__so2oj {
    min-height: 28px;
    border-radius: 5px;
    margin-bottom: 10px!important;
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-justify-content: flex-start!important;
            justify-content: flex-start!important;
    -webkit-flex-direction: row!important;
            flex-direction: row!important;
    -webkit-flex-wrap: wrap!important;
            flex-wrap: wrap!important;
    position: relative;
}

.FilterContainer_span__3hpup {
    height: 15px!important;
    padding: 0px 23px 22px 8px!important;
    margin-top: 2px;
    margin-right: 5px;
    color: white;
    border-radius: 5px;
}

.FilterContainer_icon__26-5G {
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
}

.FilterContainer_shipperName__29gYb {
    background-color: rgb(14, 55, 69)!important;
}

.FilterContainer_carrierName__2xsSo {
    background-color: rgb(176, 53, 16)!important;
}

.FilterContainer_status__1dgeo {
    background-color:  rgb(48, 85, 95)!important;
}

.FilterContainer_originCode__1ArMW {
    background-color: rgb(16, 139, 176)!important;
}

.FilterContainer_destCode__347mE { 
    background-color: rgb(95, 58, 48)!important;
}

.FilterContainer_consigneeName__26x6M {
    background-color: rgb(14, 55, 69)!important;
}

.FilterContainer_vesselName__K8KRA {
    background-color: rgb(48, 84, 150)!important;
}

.FilterContainer_voyageNumber__34kX_ {
    background-color: rgb(48, 84, 150)!important;
}

.FilterContainer_serviceRoute__ZO_h8 {
    background-color: rgb(105, 127, 133)!important;
}

.FilterContainer_pickupLocation__2Zwx7 {
    background-color: rgb(0, 133, 0);
}

.FilterContainer_returnLocation__Koiwk {
    background-color: rgb(1, 157, 1);
}

.FilterContainer_portOfLoad__2NQ3j {
    background-color: rgb(64, 186, 64);
}
.LoadingSpinner_spinnerWrapper__2hL2p {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.LoadingSpinner_spinnerWrapper__2hL2p > div {
    margin-left: 20px;
}
*{
    margin: 0px;
    padding: 0px;
}

ul{
    border-bottom: 1px gray solid;
}

button{
    color: #2196f3;
    margin: 12px;
    padding: 8px;
    font-size: 1em;
    background-color: white;
}

button:hover{
    background-color: #eee;
}

button.descriptionOfGoods_active__15vJX{
    border-bottom: #2196f3 2px solid;
}

button:focus{
    color: white;
    background-color: #2196f3;
}
/* .link{
    background-color: inherit;
    color: #2196f3;
    cursor: pointer;
}

.link:focus{
    background-color: #2196f3;
    color: white;
}

.selected{
    background-color: #2196f3;
    color: white;
} */

.invoices_thead__3CaMx {
    background-color: #444;
    color: white;
    font-size: .85em;
    border: 1px #444 solid;
}

th {
    text-align: center;
    line-height: .5em;
    border-radius: 0px;
}

.invoices_highlightedTab__Zhx5n {
    background-color: #2196f3;
    color: white;
    cursor: default;
}

.invoices_invoiceHighlightedTab__3Wy20 {
    background-color: rgb(58, 90, 159);
    ;
    color: white;
    cursor: default;
}

.invoices_commissionHighlightedTab__pdxMS {
    background-color: rgb(179, 0, 0);
    ;
    color: white;
    cursor: default;
}

.invoices_filesHighlightedTab__LUJ31 {
    background-color: #33679e;
    color: white;
    cursor: pointer;
}

.invoices_inactiveTab__Eskd_ {
    background-color: white;
    color: #0f9dff;
    cursor: pointer;
}

.invoices_invoiceInactiveTab__AkKbM {
    background-color: white;
    color: rgb(58, 90, 159);
    cursor: pointer;
}

.invoices_commissionInactiveTab__1t_Yu {
    background-color: white;
    color: rgb(179, 0, 0);
    cursor: pointer;
}

.invoices_filesInactiveTab__3usHP {
    color: black;
    cursor: pointer;
}

.invoices_highlightedTab__Zhx5n:hover {
    background-color: #2196f3;
    cursor: default;
    cursor: pointer;

}

.invoices_invoiceHighlightedTab__3Wy20:hover {
    background-color: rgb(58, 90, 159);
    cursor: default;
    cursor: pointer;

}

.invoices_commissionHighlightedTab__pdxMS:hover {
    background-color: rgb(179, 0, 0);
    cursor: default;
    cursor: pointer;
}

.invoices_filesHighlightedTab__LUJ31:hover {
    cursor: pointer;
}

.invoices_commissionDateAndUsers__2dcTx {
    color: rgb(179, 0, 0);
}

.invoices_invoiceDateAndUsers__2WmqC {
    color: rgb(58, 90, 159);
}
.location_trash__2x7op {
    color:rgb(228, 28, 2);
}


.location_hidden__3Vk-F {
    display: none;
}

.location_table__3VNp2 {
    font-size: 1rem;
}

.location_table__3VNp2>tbody>tr>td>div>input {
    padding: 0px 0px 0px 4px;
    margin: 0px;
    font-size: 1rem;
    height: 20px;
}

.location_table__3VNp2>tbody>tr>td>div>select {
    padding: 0px 0px 0px 4px;
    margin: 0px;
    font-size: 1rem;
    height: 20px;
}

.location_table__3VNp2>tbody>tr>td>div {
    padding: 0px !important;
    margin: 0px;
}

.location_containerNumber__2sFHl {
    width: 174px;
}

.location_sealNumber__7VdnV {
    width: 174px;
}

.location_weight__3I3Qn {
    width: 100px;
}

.location_unitOfMeasurement__MKtQi {
    width: 100px;
}

.location_count__X7ZXF {
    width: 68px;
}

.location_unit__1lwMx {
    width: 96px;
}

.location_cubicMeters__31mLq {
    width: 100px;
}

.location_hsCode__FrzNj {
    width: 100px;
}

.location_description__2UNG6 {
    width: auto;
}

.location_price__2uUCS {
    width: 80px;
}

.location_toast__1IxVX {
    border: 1px black solid;
    background-color: #444;
    border-radius: 4px;
}

.location_successToast__cmxyn {
    padding: 6px;
    min-width: 200px;
    background-color: #444;
    color: #4caf50;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    font-size: 1em;
    font-weight: 500;
}

.location_errorToast__1aoAi {
    padding: 6px;
    min-width: 200px;
    background-color: #444;
    color: #f44336 ;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    font-size: 1em;
    font-weight: 500;
}

.location_shipmentIcon__1etY-:hover{
    color: #2196f3;
    cursor: pointer;
}

.location_delete__1wNSb{
    color: #f44336;
    position: relative;
    top: 3px;
}

.location_delete__1wNSb:hover{
    border-radius: 3px;
    background-color: #f44336;
    color: white;
}

.location_containerSelected__2Zpn4{
    background-color: #2196f3;
}

.location_hide__H_7p9{
    display:none;
}

i, td, th{
    cursor: pointer;
}

.location_bookingNumber__fX-kV{
    padding: 0px;
    margin: 0px;
    font-size: 1.2em;
}

/* .sticky{
    position: sticky;
    width: 100%;
    top: 112px;
}

.sticky>th{
    width: 100%;
} */

.location_notes__3XnSe {
    color: grey;
    font-style: italic;
}

.lf-portal-table {
    background-color: white;
    font-weight: 400;
    font-size: .9em;
    margin-top: 1px;
 
}

.lf-portal-thead {
    background-color: #33679e;
    color: white;
    max-height: 40px;
    line-height: 1;
}

.lf-portal-thead>tr>th {
    position: relative;
    border-radius: 0px;
    text-align: center;
    font-size: 1em;
    max-height: 40px;
}

.lf-portal-tbody>tr>td {
    border-radius: 0px;
    text-align: left;
    
}

td, tr{
    padding: 4px;
}

tbody>tr:hover{
    text-shadow: #33679e;
    box-shadow: #33679e;
    background-color: #f0f3f7
}

.lf-portal-tbody>tr {
    margin: 2px;
    border: 1px solid white;
    
}

.lf-portal-tbody>tr:hover {
    background-color: #eee;
}

.lf-portal-tbody>tr.selected {
    color: white;
    background-color: #2196f3;
    border: 1px solid white;
}

.lf-portal-tbody>tr.menu-selected {
    color: white;
    background-color: rgb(255, 174, 69);
    border: 1px solid white;
}

.lf-portal-tbody>tr>td {
    margin: 0px;
    padding: 0px;
    padding: 5px 5px 5px 12px;
}

.lf-shipment-context-menu>ul>li:hover {
    background-color: #ddd;
}

.lf-portal-tbody>tr>td.no-margin-no-padding {
    margin: 0px;
    padding: 0px;
    width: 1px !important;
}

.lf-portal-tbody>tr>td.no-margin-no-padding>span.badge {
    margin: 4px;
    padding: 0px;
}

span.badge.new:after {
    content: none;
}
.Documentation_rowHighlight__2L2X0 {
    margin-left: -5px;
    border-radius: 5px;
    outline: solid #2196f3 !important;
    z-index: 100;
}
.Reports_reportTable__ReRtV {
    display: inline-block !important;
}
.contracts_overviewCard__1Lh8J {
    height: 60px;

}

.contracts_contractCard__1tOdB {
    background-color: #33679e !important;
    color: white;
}

.contracts_contractModal__3iTQs {
    height: 83% !important;
    max-height: 100% !important;
    width: 99%;
    overflow: auto !important;
}

.contracts_modalStepButtons__3CtiV {
    width: 100% !important
}

.contracts_modalStepButtonsLeft__3-3E4 {
    bottom: -45px;
    left: 100px;
    float: left !important;
}

.contracts_modalStepButtonsRight__3fUhi {
    bottom: 0px;
    right: 100px;
    float: right !important;
}

.contracts_contractsTbody__vBrWf {
    position: relative;
    /* display: inline-block;
    text-align: left; */
    height: 200px;
}

.contracts_contractLoadingSpinner__3xp8y {
    margin-top: 20%;
    height: 300px !important;
    width: 300px !important;
}

.contracts_transparentSpinner__27FGa {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 500;
    background-color: rgb(120, 120, 120, .2);
}
.ratesDisplayHeader {
    text-align: center;
}

.tableHeaderLeftAlign > th{
    text-align: left!important;
}

.ratesForm{
    max-width: 55px;
    max-height: 25px;
    background-color: rgb(247, 203, 121);
}

.ratesTable {
    display: inline-block;
    /* overflow: auto; */
}

.ratesTable > thead > tr > th {
    max-height: 10px!important;
    height: 10px!important;
    padding: 0px 10px 0px 0px;
}

.ratesTable > tbody > tr {
    max-height: 30px!important;
    height: 35px!important;
}

.fixedSize > tbody > tr {
    max-height: 30px!important;
    height: 30px!important;
}

.fixedSize > thead > tr > th {
    max-width: 300px!important;
    width: 370px!important;
}

.displayTable > tbody > tr {
    max-height: 30px!important;
    height: 35px!important;
    font-size: 14px!important;
}

.rateTableRow > td {
    max-width: 20px!important;
}

.formDropdown {
    cursor: pointer;
    font-weight: 500;
}

.rateSubscript{
    color: darkgrey
}

.addOriginIcon {
    padding: 3px 0px 0px 0px;
    margin-right: -5px;
}

.originDest-tooltip {
    position: relative;
  }
  
.originDest-tooltip .originDest-tooltiptext {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 15px;
    bottom: 30px;
    z-index: 4000!important;
    border-radius: 15px 15px 15px 0px;
    width: -webkit-max-content;
    width: max-content;
    height: 30px;
    font-weight: 400;
    line-height: 10px;
    margin: 0px!important;
}
  
.originDest-tooltip:hover .originDest-tooltiptext {
    visibility: visible;
}
  

@-webkit-keyframes bounce{
    0% { font-size: 14px; }
    25% { font-size: 16px; color: red;}
    50% { font-size: 18px; }
    75% { font-size: 16px; }
    100% { font-size: 14px; }
}
  

@keyframes bounce{
    0% { font-size: 14px; }
    25% { font-size: 16px; color: red;}
    50% { font-size: 18px; }
    75% { font-size: 16px; }
    100% { font-size: 14px; }
}

.specRestSubscript{
    position: absolute;
    top: 0px;
    left: -2px;
    color: red;
    font-size: 14px;
    -webkit-animation: bounce 2s linear 2s infinite;
            animation: bounce 2s linear 2s infinite;
}

.contractShipperDropdown{
    background-color: #33679e!important;
    color: orange;
    font-weight: 600;
    margin-left: -10px;
    font: 18px;
    height: 25px;
    margin-top: 5px;
}

.contractShipperDropdownLabel{
    background-color: #33679e!important;
    color: orange;
    margin-left: -10px;
    margin-top: -5px!important;
    margin-bottom: 10px!important;
}

.contractEditInputs {
    margin-left: -10px;
    font: 18px;
    height: 25px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.contractEditCheckBox{
    max-width: 300px;
}

.contractEditInputsLabel{
    background-color: white!important;
    margin-left: -10px;
    height: 12px;
    line-height: 12px;
    margin-top: 10px;
}

.ACTIVE {
    color: green!important;
}

.CLOSED {
    color: grey;
}

.ARCHIVED {
    color: black;
}

.PENDINGREVIEW {
    color: purple;
}

.UPCOMING {
    color: rgb(66, 119, 255);
}

.PENDINGUPCOMING {
    color: purple;
}

.freetime-dot{
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin-bottom: 2px;
    margin-left: -10px!important;
}

.freetime-origin-tooltip {
    position: relative;
    color: red!important;
  }
  
  .freetime-origin-tooltip .freetime-tooltiptext {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 100px;
    bottom: -15px;
    z-index: 4000!important;
    border-radius: 0px 15px 15px 15px;
    width: 250px;
    height: 30px;
    font-weight: 400;
    line-height: 10px;
    margin: 0px!important;
  }
  
  .freetime-origin-tooltip:hover .freetime-tooltiptext {
    visibility: visible;
  }

.freetime-tooltip {
  position: relative;
  color: red!important;
}

.freetime-tooltip .freetime-tooltiptext {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
  position: absolute;
  left: 100px;
  bottom: 20px;
  z-index: 4000!important;
  border-radius: 15px 15px 15px 0px;
  width: 250px;
  height: 30px;
  font-weight: 400;
  line-height: 10px;
  margin: 0px!important;
}

.freetime-tooltip:hover .freetime-tooltiptext {
  visibility: visible;
}

.freightFeesTable {
    font-size: 12px!important;
}

.freightFeesTable > tbody > tr > td {
    line-height: 15px;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}

.freightFeesTable > thead > tr > th {
    padding-bottom: 0px!important;
    padding-top: 0px!important;
    height: 0px!important;
}

.freightFeesRow {
    height: 50px!important;
    padding: 20px!important;
    margin: 0px!important;
    line-height: 2px!important;
}

.removeMargins {
    margin-bottom: 0px;
    padding-top: 30px!important;
}

.demurrageWidth {
    width: 40px!important;
}

.flagButton > button{
    height: 20px;
    width: 20px!important;
}

.flagButtonIcon {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}

.flag-icon {
    border: 1px solid rgb(218, 217, 217);
    height: 16px;
    width: 20px!important;
    margin-top: 4px;
    float: right;
    margin-left: 10px;
}

.editIcons {
    text-align: center;
    padding: 0px!important;
    margin: 0px!important;
}

.saveIcon {
    margin-top: 0px!important;
    font-size:35px;
}

.editAndDeleteIcons{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    color: black!important;
}

.modalSaveIcon{
    margin-top: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: black!important;
}

.manageButtonIcon{
  margin: 0px!important;
  padding: 0px!important;
}

.manageButton{
  padding-left: 3px!important;
  padding-right: 5px!important;
  margin-left: 0px!important;
}

.manageButton > button {
    padding: 3px;
}

.feeAddButton > button {
    margin-top: 2px;
    font-size: 12px;
    height: 19px!important;
}

.smallForm {
    height: 20px!important;
    font-size: 12px;
}

.smallFormLabel {
    font-size: 10px;
    font-weight: 500;
}

.contractStatus.menu-selected {
    background-color: rgb(255, 174, 69)!important;
    color: white!important;
    border-bottom: 2px solid rgb(255, 174, 69)!important;
    border-top: 2px solid rgb(255, 174, 69)!important;
}

.contractEditHeader{ 
    color: white;
    min-height: 170px;
}

.salesRepStyle{
    width: 100px;
}

.contractLineageButtonContainer {
    margin-top: 45px;
    text-align: center!important;
}

.contractLineageButton{
    font-size: 50px!important;
    text-align: center!important;
}

.childParentContractCount {
    font-size: 16px;
    margin-top: -30px!important;
}


.addAndDeleteSpan>i{
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-flex: 2 1!important;
            flex: 2 1!important;
    -webkit-justify-content: space-around!important;
            justify-content: space-around!important;
}

.feeTotals{
    font-weight: 700;
}

.contractDates {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 3 1;
            flex: 3 1;
}

.dateMargins {
    margin-left: 20px;
    margin-right: 20px;
}

.dateLabels{
    margin-top: -20px!important;
    margin-bottom: 15px!important;
}

.contractStatus{
    font-size: 22px;
    margin-top: -8px;
    text-align: center!important;
    font-weight: 700;
    text-shadow: -1px -1px 0 rgb(133, 133, 133), 1px 1px 0 rgb(206, 206, 206), 1px 1px 0 rgb(167, 167, 167), 1px 1px 0 rgb(255, 255, 255);
}

.highlightRate{
    background-color: yellow;
}

.specialRestrictionCol{
    max-width: 500px;
}

.demurrageWidth{ 
    max-width: 900px!important;
}

.demurrageCombo {

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    max-width: 300px;
}

.freetimePortWidth {
    max-width: 150px;
}

.chargeSumSwitch {
    float: right!important;
    margin-right: 50px!important;
}

th {
    text-align: left;
}

.modalAddOriginRow {
    margin-top: 30px!important;
}

.contractEditCheckBox[type="checkbox"].filled-in:checked + span:not(.lever):after {
    border: 2px solid rgb(255, 255, 255);
    background-color:rgb(0, 0, 255);
    color: white;
}

.contractEditCheckBox {
    color: white!important;
}
#Requests_bookingRequestModal__1ww4t {
    width: 70%;
    margin-bottom: 0px;
    overflow-y: auto;
    border-radius: 10px;
}

.Requests_bookingRequestModalBody__2LY8t {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid black;
    background-color: #ffffcc;
    border-radius: 5px;
}

.Requests_shipperViewText__EWZ1L {
    color: #2a7e19;
}

.Requests_saveAndEditButton__1XQqV, .Requests_saveAndEditButton__1XQqV:hover {
    position: absolute;
    left: 1px;
}

.Requests_closeIcon__1jFUe {
    float: right;
    margin-bottom: 0px!important;
}

.Requests_noMarginBottom__23Pzq { 
    margin-bottom: 0px!important;
}

.Requests_smallMarginBottom__2PU6W {
    margin-bottom: 10px!important;
}

.Requests_modalHeader__1inPU {
    margin-top: 0px;
    text-align: center;
    font-size: 22px;
}

.Requests_userSection__1f309 {
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 10px!important;
    margin-bottom: 0px;
    margin-top: -10px;
}

.Requests_commentsRow__3iYh- > h6 {
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    margin-bottom: 8px;
}

.Requests_commentTextArea__3I6zh > textarea{
    min-height: 30px!important;
}

.Requests_noteRow__33fKd {
    min-height: 22px!important;
    position: relative;
    margin-bottom: 0px;
}

.Requests_noteContainer__3zXqB{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

}

.Requests_noteSpan__248-2 {
    font-weight: 300;
    white-space: pre;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.Requests_noteLabel__3nW-0 {
    font-style: italic!important;
    color: #9e9e9e!important;
    font-size: .8em;
    font-weight: 300;
}

.Requests_addCommentRow__3UA5d {
    margin-bottom: 0px;
}

.Requests_commentBox__1fALK {
    margin-bottom: 10px;
}

.Requests_commentHeader__1VXeu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.Requests_commentName__xVY3Z {
    margin-right: 10px;
    font-weight: 500;
    color: grey;
    font-size: 14px;
}

.Requests_commentTime__3s2Yw {
    font-size: 12px;
    padding-top: 3px;
    font-weight: 500;
    color: grey;
}

.Requests_commentBody__21ijZ {
    font-size: 16px;
    margin-left: 1em!important;
}

.Requests_requestDates__3J0C1 {
    margin-bottom: 15px;
}

.Requests_datetimeRequestedDiv__2Zz8_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    text-align: left;
}

.Requests_datetimeAdvancedDiv__2jS6K {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.Requests_datetimeCompletedDiv__1zyHt {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    text-align: right;
}

.Requests_alignRight__3Vu0D {
    text-align: right;
}

.Requests_requestDates__3J0C1 > div >  label {
    font-style: italic;
}

.Requests_requestDates__3J0C1 > div > span {
    font-size: 14px;
}

.Requests_contractInfo__24A0r{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.Requests_specialRestrictionNote__24f_I {
    background-color: rgb(255, 220, 155);
    padding: 10px;
    border-radius: 5px;
    color:rgb(172, 95, 0);
}

.Requests_specialRestrictionLabel__iOLQw {
    font-weight: 500;
}


.Requests_bookingNumberLabel__1Ci6l {
    margin-top: -12px!important;
    position: absolute;
    font-style: italic;
}

.Requests_bookingNumberSpan__1gkrG {
    position: absolute;
}

.Requests_requestProgressContainer__2RgCk {
    background-color: rgb(166, 228, 156)!important;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    position:relative;
}

.Requests_requestProgress__OgD6- {
    height: 16px!important;
    padding: 0px!important;
    margin: 0px!important;
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-flex-direction: column!important;
            flex-direction: column!important;
    -webkit-justify-content: center!important;
            justify-content: center!important;
}

.Requests_statusMessage__3Dhex {
    padding-bottom: 1px!important;
}

.Requests_requestView__135wx {
    background-color: rgb(255, 255, 255)!important;
    border:rgb(120, 111, 115);
    color: rgb(130, 130, 130)!important;
}

#Requests_templateModal__3ql5n {
    width: 40%;
    border-radius: 8px;
}

.Requests_templateListRow__12l5K {
    font-weight: 400;
    border-radius: 4px;
    padding-top: 5px;
    box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.5);
}

.Requests_templateListRow__12l5K > span > i {
    color: red;
}

.Requests_progress__33-d_ div {
    display: block;
    height: 100%;
}

.Requests_NEW__3xPDg, .Requests_REQUESTED__3PQVg, .Requests_PENDING__o_qFe, .Requests_DECLINED__3eF9T, .Requests_COMPLETED__22Q8S, .Requests_CLOSED__1M0L_, .Requests_UPDATING__1EhZ-, .Requests_DELETED__1tpA7 {
    font-weight: 700;
}

.Requests_NEW__3xPDg {
    color:seagreen;
}

.Requests_NEWdeterminateContainer__1wmPx {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_NEWdeterminate__1GmjF {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      rgb(75, 145, 75) 1rem,
      rgb(75, 145, 75) 2rem
    );
    background-color: rgb(37, 228, 37)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}


.Requests_PENDING__o_qFe {
    color: #329ef4;
}

.Requests_PENDINGdeterminateContainer__KGGlk {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_PENDINGdeterminate__22___ {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    -webkit-animation: Requests_progressBar__3cLEP 10s infinite linear;
            animation: Requests_progressBar__3cLEP 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.Requests_UPDATING__1EhZ- {
    color: #329ef4;
}

.Requests_UPDATINGdeterminateContainer__2z9Pr {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_UPDATINGdeterminate__2kqqY {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    -webkit-animation: Requests_progressBar__3cLEP 10s infinite linear;
            animation: Requests_progressBar__3cLEP 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}


.Requests_DECLINED__3eF9T {
    color: #f44336;
}

.Requests_DECLINEDdeterminateContainer__3re_g {
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_DECLINEDdeterminate__xC9F9 {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem,
      transparent 1.7rem,
      rgb(201, 55, 55) 1rem,
      rgb(201, 55, 55) 2rem
    );
    background-color: #f44336!important;
    background-size: 200% 200%;
    -webkit-animation: Requests_progressBar__3cLEP 100s infinite linear;
            animation: Requests_progressBar__3cLEP 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.Requests_COMPLETED__22Q8S {
    color: rgb(130, 130, 130);
}

.Requests_COMPLETEDdeterminateContainer__3vf8F {
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_COMPLETEDdeterminate__2KI0m {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(211, 211, 211) 1rem,
      rgb(211, 211, 211) 2rem
    );
    background-color: rgb(130, 130, 130)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.Requests_CLOSED__1M0L_ {
    color: black;
}

.Requests_CLOSEDdeterminateContainer__62TKP {
    margin: 200px;
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_CLOSEDdeterminate__3_8KX {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(120, 120, 120) 1rem,
      rgb(120, 120, 120) 2rem
    );
    background-color: rgb(0, 0, 0)!important;
    background-size: 200% 200%;
    -webkit-animation: Requests_progressBar__3cLEP 100s infinite linear;
            animation: Requests_progressBar__3cLEP 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}

.Requests_REQUESTED__3PQVg {
    color: rgb(255, 140, 0);
}

.Requests_REQUESTEDdeterminateContainer__2o_TL {
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.Requests_REQUESTEDdeterminate__3lffP {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(177, 138, 69) 1rem,
      rgb(177, 138, 69) 2rem
    );
    background-color: rgb(255, 140, 0)!important;
    background-size: 200% 200%;
    -webkit-animation: Requests_progressBar__3cLEP 20s infinite linear;
            animation: Requests_progressBar__3cLEP 20s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
}


.Requests_statusContextMenu__2N1VL, .Requests_statusContextMenu__2N1VL > ul {
    z-index: 5000!important;
    background-color: red !important;
    overflow: visible!important;
    cursor: pointer;
}

.Requests_loveTables__2cTjO {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.Requests_loveTables__2cTjO td {
    border: 1px solid black;
    white-space: pre;
    border-collapse: collapse;
}

.Requests_loveTables__2cTjO  tr {
    border-bottom: none!important;
}

.Requests_centerTable__6hswj {
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-justify-content: center!important;
            justify-content: center!important;
    -webkit-flex-direction: row!important;
            flex-direction: row!important;
}

.Requests_toolTipCommentContainer__1bDVF {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 300px;
}

.Requests_toolTipCommentAuthor__3vAp7 {
    text-align: left;
    font-style: italic;
}

.Requests_toolTipComment__bBpen {
    text-align: left;
}

.Requests_defaultEmptyRow__2qL9I {
    min-height: 40px!important;
}

.Requests_buttonRow__3C55p{ 
    max-height: 10px!important;
    padding-bottom: 10px!important;
    margin-bottom: -20px!important;
}

.Requests_reminderButtons__qzR5f {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.Requests_buttonRow__3C55p > div {
    margin-bottom: 10px!important;
}

.Requests_reminderRow__3H5m1 {
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
    height: 30px;
    max-height: 30px;
    padding-top: 2px;
    width: 102%
}

.Requests_reminderRow__3H5m1 > label {
    position: absolute;
    top: -10px;
    left: 4px;
    background-color: white;
}

.Requests_unread__jPHaL {
    background-color: rgb(255, 227, 136);
}

.Requests_requiresAttention__2pshi {
    background-color:rgb(173, 173, 255);
}

.Requests_showAllContractsRow__2vvlM:hover {
    cursor: pointer;
}

.Requests_showAllContractsRow__2vvlM {
    cursor: pointer;
    text-decoration: underline;
}

  .Requests_disabledStyle__3KJyP > button {
      background-color: rgb(211, 211, 211)!important;
      color:rgb(184, 184, 184)!important;
      border: 1px solid white!important;
  }

  .Requests_disabledButtonToolTip__eu1GM .Requests_disabledButtonToolTipText__2nDcw {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 100px;
    bottom: 175px;
    z-index: 4000!important;
    border-radius: 0px 15px 15px 15px;
    border: 1px solid black;
    width: 200px;
  }
  

  .Requests_disabledButtonToolTip__eu1GM:hover .Requests_disabledButtonToolTipText__2nDcw {
    visibility: visible;
  }

  .Requests_containerCountToolTip__11Jlq .Requests_containerCountToolTipText__IqDah {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    text-align: left;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-size: 12px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 300px;
    top: 155px;
    z-index: 4000!important;
    border-radius: 15px 15px 15px 0px;
    border: 1px solid black;
    width: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Requests_containerCountToolTip__11Jlq > .Requests_containerCountToolTipText__IqDah {
    background-color: rgb(211, 211, 211)!important;
    color:rgb(184, 184, 184)!important;
    border: 1px solid white!important;
  }

  .Requests_containerCountToolTip__11Jlq:hover .Requests_containerCountToolTipText__IqDah {
      visibility: visible;
  }

  .Requests_newRequestHeaderClose__19xx9 > .Requests_collapsibleHeaderForms__33sJU {
    opacity: 0;
    -webkit-animation: Requests_disappear__3sEt2 .5s;
            animation: Requests_disappear__3sEt2 .5s;
  }

  .Requests_newRequestHeaderOpen__1Hj9k > .Requests_collapsibleHeaderForms__33sJU {
      display: -webkit-flex;
      display: flex;
      -webkit-animation: Requests_appear__2faSo .5s;
              animation: Requests_appear__2faSo .5s;
  }

  .Requests_collapsibleHeaderForms__33sJU {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      float: right!important;
      text-align:right!important;
      width: 100%;;
  }

  .Requests_newRequestHeaderOpen__1Hj9k {
    margin-bottom: 20px!important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .Requests_newRequestHeaderClose__19xx9 {
    height: 26px!important;
    margin-bottom: 20px!important;
    display: -webkit-flex;
    display: flex;
  }

  .Requests_newRequestHeaderOpen__1Hj9k > p, .Requests_newRequestHeaderClose__19xx9 > p {
      cursor: pointer;
  }

  .Requests_newRequestForm__1VQsf {
    border-radius: 10px;
    background-color: rgb(237, 237, 237);
    padding-left: 22px!important;
    margin-bottom: 10px!important;
    display: block;
  }

  .Requests_openForm__1RdD9 {
      -webkit-animation-name: Requests_collapsible__1qvKm;
              animation-name: Requests_collapsible__1qvKm;
      -webkit-animation-duration: .5s;
              animation-duration: .5s;
      padding: 10px;
      padding-top: 15px!important;
      overflow: visible;
  }

  .Requests_closeForm__11xa3 {
    -webkit-animation-name: Requests_collapsible-close__3BBWR;
            animation-name: Requests_collapsible-close__3BBWR;
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
    height: 0px;
    margin: 0px!important;
    border: none!important;
    overflow:hidden!important;
  }

  .Requests_containerCountsSpan__1BXTx {
    color: black;
  }

  .Requests_bookingRequestForm__32Axg {
      border-bottom: 1px solid rgb(0, 0, 0);
      margin-bottom: 10px;
  }

  .Requests_italicComment__17tvH {
      font-style: italic;
  }

  .Requests_saveReminderButton__1HzHZ {
      padding: 0px;
      margin: 0px;
      color: black;
      height: 30px;
  }

  .Requests_saveReminderButton__1HzHZ:focus {
    border: 1px solid rgb(38, 186, 255);
    border-radius: 4px;
    background-color: white;
    color: black;
  }

  .Requests_statusDisplay__3GqeB {
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    height: 30px;
    width: calc(16.6666666667% - 12px)!important;
    padding-top: 4px!important;
    margin-right: 12px!important;
    position: relative!important;
  }

  .Requests_statusDisplayLabel__3Qvu8 {
    position: absolute;
    top: -9px;
    left: 2px;
    background-color: white;
    font-weight: 400!important;
  }

  .Requests_editRequestIndex__3Igwu {
    box-shadow:inset 0px 0px 0px 1px rgb(0, 0, 0)!important;
  }

  .Requests_notificationIcon__2SlzY {
    width: 5px!important;
} 

  .Requests_notificationIcon__2SlzY > i {
    padding-top: 4px!important;
    -webkit-transform: scale(.8);
            transform: scale(.8);
}

.Requests_statusMemoColumn__3asWe {
    min-width: 200px;
}

  @media screen and (max-width: 1200px) {

    .Requests_containerCountsSpan__1BXTx{
        font-size: 10px;
    }
  }

  @-webkit-keyframes Requests_collapsible__1qvKm {
      from { 
          height: 0px;
          padding: 0px; 
          overflow: hidden;
      }
      to {
          height: 214px;
          padding: 10px;
          overflow: visible;
      }
  }

  @keyframes Requests_collapsible__1qvKm {
      from { 
          height: 0px;
          padding: 0px; 
          overflow: hidden;
      }
      to {
          height: 214px;
          padding: 10px;
          overflow: visible;
      }
  }

  @-webkit-keyframes Requests_collapsible-close__3BBWR {
      from {
          height: 214px;
          padding: 10px;
          border: 1px solid black!important;
      }
      to {
          padding: 0px!important;
          height: 0px;
          border: none;
      }
  }

  @keyframes Requests_collapsible-close__3BBWR {
      from {
          height: 214px;
          padding: 10px;
          border: 1px solid black!important;
      }
      to {
          padding: 0px!important;
          height: 0px;
          border: none;
      }
  }

  @-webkit-keyframes Requests_appear__2faSo {
      from {
          display: none;
          opacity: 0;
      }
      to {
          display: -webkit-flex;
          display: flex;
          opacity: 1;
      }
  }

  @keyframes Requests_appear__2faSo {
      from {
          display: none;
          opacity: 0;
      }
      to {
          display: -webkit-flex;
          display: flex;
          opacity: 1;
      }
  }

  @-webkit-keyframes Requests_disappear__3sEt2 {
      from {
          display: -webkit-flex;
          display: flex;
          opacity: 1;
      }
      to {
          opacity: 0;
      }
  }

  @keyframes Requests_disappear__3sEt2 {
      from {
          display: -webkit-flex;
          display: flex;
          opacity: 1;
      }
      to {
          opacity: 0;
      }
  }


@-webkit-keyframes progressBar {
    100% {
        background-position: 0% 0%;
    }
    0% {
        background-position: 95% 95%;
    }
}

@keyframes progressBar {
    100% {
        background-position: 0% 0%;
    }
    0% {
        background-position: 95% 95%;
    }
}

/* .NEW { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .NEW > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      rgb(75, 145, 75) 1rem,
      rgb(75, 145, 75) 2rem
    );
    background-color: rgb(37, 228, 37)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */



/* .PENDING { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .PENDING > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    animation: progressBar 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .UPDATING { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .UPDATING > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    animation: progressBar 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .DECLINED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .DECLINED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem,
      transparent 1.7rem,
      rgb(201, 55, 55) 1rem,
      rgb(201, 55, 55) 2rem
    );
    background-color: #f44336!important;
    background-size: 200% 200%;
    animation: progressBar 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */


/* .COMPLETED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.COMPLETED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(211, 211, 211) 1rem,
      rgb(211, 211, 211) 2rem
    );
    background-color: rgb(130, 130, 130)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .CLOSED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.CLOSED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(120, 120, 120) 1rem,
      rgb(120, 120, 120) 2rem
    );
    background-color: rgb(0, 0, 0)!important;
    background-size: 200% 200%;
    animation: progressBar 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .REQUESTED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.REQUESTED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(177, 138, 69) 1rem,
      rgb(177, 138, 69) 2rem
    );
    background-color: rgb(255, 140, 0)!important;
    background-size: 200% 200%;
    animation: progressBar 20s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */


.newRequestEmployee {
    background-color: #FFE388;
    -webkit-animation-name: employeeFirework;
            animation-name: employeeFirework;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }

  .newRequestCustomer {
    background-color: white;
    -webkit-animation-name: customerFirework;
            animation-name: customerFirework;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }

  .isDateRangeCheckBox, .isDateRangeCheckBox:hover {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .isDateRangeCheckBox > div > p > label,   .isDateRangeCheckBox:hover > div > p > label {
    width: 70px;
  }

  .isDateRangeCheckBox > div, .isDateRangeCheckBox:hover > div {
    width: 70px;
  }

  .isDateRangeCheckBox > div > p, .isDateRangeCheckBox:hover > div > p {
    width: 70px!important;
  }

  .resultsDisplay.menu-selected {
    color: white;
    background-color: rgb(255, 174, 69);
    border-left: 1px rgb(255, 174, 69) solid;
  }

  .resultsDisplay.menu-selected > td {
    border-bottom: 1px white solid!important;
    border-left: 1px rgb(255, 174, 69) solid!important;
    border-right: 1px rgb(255, 174, 69) solid!important;

  }
  
  @-webkit-keyframes employeeFirework {
    0%   {
      background-color:#33679E;
      color: white;
    }
    5%{
      background-color:#46729C;
      color: #eff4f9;
    }
    15%   {
      background-color: #587E9A;
      color: #d9e5f1;
    }
    25%{
      background-color:#6B8998;
      color: #c3d8ef;
    }
    35%   {
      background-color: #7D9496;
      color: #a8c8eb;
    }
    45%{
      background-color:#909F94;
      color: #76a6d8
    }
    55%{
      background-color: #A2AB92;
      color: #5287bf;
    }
    65%{
      background-color:#B5B690;
      color: #457ab3;
    }
    75%{
      background-color: #C7C18E;
      color: #33679e;
    }
    85%{
      background-color:#DACC8C;
      color: black;
    }
    95%   {
      background-color: #ECD88A;
      color: black;
    }
    100%   {
      background-color: #FFE388;
      color: black;
    }
  }
  
  @keyframes employeeFirework {
    0%   {
      background-color:#33679E;
      color: white;
    }
    5%{
      background-color:#46729C;
      color: #eff4f9;
    }
    15%   {
      background-color: #587E9A;
      color: #d9e5f1;
    }
    25%{
      background-color:#6B8998;
      color: #c3d8ef;
    }
    35%   {
      background-color: #7D9496;
      color: #a8c8eb;
    }
    45%{
      background-color:#909F94;
      color: #76a6d8
    }
    55%{
      background-color: #A2AB92;
      color: #5287bf;
    }
    65%{
      background-color:#B5B690;
      color: #457ab3;
    }
    75%{
      background-color: #C7C18E;
      color: #33679e;
    }
    85%{
      background-color:#DACC8C;
      color: black;
    }
    95%   {
      background-color: #ECD88A;
      color: black;
    }
    100%   {
      background-color: #FFE388;
      color: black;
    }
  }

  @-webkit-keyframes customerFirework {
    0%   {
      background-color:#33679E;
      color: white;
    }
    5%{
      background-color:#4675A7;
      color: #eff4f9;
    }
    15%   {
      background-color: #5883B0;
      color: #d9e5f1;
    }
    25%{
      background-color:#6B90B8;
      color: #c3d8ef;
    }
    35%   {
      background-color: #7D9EC1;
      color: #a8c8eb;
    }
    45%{
      background-color:#90ACCA;
      color: #76a6d8
    }
    55%{
      background-color: #A2BAD3;
      color: #5287bf;
    }
    65%{
      background-color:#B5C8DC;
      color: #457ab3;
    }
    75%{
      background-color: #C7D6E5;
      color: #33679e;
    }
    85%{
      background-color:#DAE3ED;
      color: black;
    }
    95%   {
      background-color: #ECF1F6;
      color: black;
    }
    100%   {
      background-color: #FFFFFF;
      color: black;
    }
  }

  @keyframes customerFirework {
    0%   {
      background-color:#33679E;
      color: white;
    }
    5%{
      background-color:#4675A7;
      color: #eff4f9;
    }
    15%   {
      background-color: #5883B0;
      color: #d9e5f1;
    }
    25%{
      background-color:#6B90B8;
      color: #c3d8ef;
    }
    35%   {
      background-color: #7D9EC1;
      color: #a8c8eb;
    }
    45%{
      background-color:#90ACCA;
      color: #76a6d8
    }
    55%{
      background-color: #A2BAD3;
      color: #5287bf;
    }
    65%{
      background-color:#B5C8DC;
      color: #457ab3;
    }
    75%{
      background-color: #C7D6E5;
      color: #33679e;
    }
    85%{
      background-color:#DAE3ED;
      color: black;
    }
    95%   {
      background-color: #ECF1F6;
      color: black;
    }
    100%   {
      background-color: #FFFFFF;
      color: black;
    }
  }
  
  .preload * {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
  }

  .lf-portal-table-requests {
    background-color: white;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
  }
.ServiceRoute_noMarginBottom__nlZrf {
    margin-bottom: 0px;
}

.ServiceRoute_servicesTable__3xfes {
    font-size: 12px;
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    display: inline-block;
    text-align: left;
}

.ServiceRoute_servicesTable__3xfes>tr>td {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}

.ServiceRoute_carrierLabel__1fmbS {
    height: 10px;
}

.ServiceRoute_leftTable__Nx2OK {
    border-right: 1px solid black;
}

.ServiceRoute_loadingSpinnerWrapper__3tEtK {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 100px;
}

.ServiceRoute_tableDisplay__1SSYo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: flex-start !important;
            flex-direction: flex-start !important;
}

.ServiceRoute_yangMingResultsDisplay__3_9sN:nth-child(odd) {
    background-color: rgb(213, 213, 213);
}

.ServiceRoute_yangMingResultsDisplay__3_9sN:nth-child(even) {
    /* background-color: #eaeaea; */
}

.ServiceRoute_serviceTableHeader__3FbwX {
    background-color: #33679e;
    color: white;
}

.ServiceRoute_locationCount__2R8q6 {
    color: rgb(142, 142, 142);
}

.ServiceRoute_shipperCount__3BMt1 {
    color: black;
}

.ServiceRoute_additionalText__2rXVA {
    color: red;
}

.ServiceRoute_carrierField__22iMu>select,
.ServiceRoute_carrierField__22iMu>input {
    border: 2px solid #33679e !important;
}
.Forecast_standingListModal__2oTL4 {
    width: 80%;
    overflow-y: scroll;
}

.Forecast_standingListModal__2oTL4>tr>td {
    white-space: pre;
}

.Forecast_standingListTable__1Jijm {
    display: inline-block;
    margin-bottom: 10px;
}

.Forecast_resultsContainer__2lHT7 {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
    justify-content: flex-start !important;
    -webkit-justify-content: flex-start !important;
}

.Forecast_weekListContainer__37e9s {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Forecast_weekListTable__2Zq2Z {
    display: inline-block;
    width: inherit !important;
}

.Forecast_weekListTableEdit__3Vp4B {
    display: block;
    width: 1250px !important;
    max-width: 1250px !important;
    min-width: 1250px !important;
}

.Forecast_weekListTable__2Zq2Z>thead {
    background-color: #33679e;
    color: white;
}

.Forecast_weekListTable__2Zq2Z>thead>tr>th {
    border-right: 1px solid white;
    font-size: 12px;
}

.Forecast_weekListTbody__uifK5>tr>td {
    padding: 0px 4px 0px 4px;
    border: 1px solid grey;
    font-size: 12px;
    white-space: pre !important;
}

.Forecast_editTableRow__hFttP>td:not(.Forecast_containerCountAndTypeCol__20Ru8):not(.Forecast_assigneeCell__1UWuX):not(.Forecast_requestNameCell__10HaQ) {
    padding: 0px !important;
    padding-bottom: 0px !important;
    margin: 0px !important;
    height: 22px !important;
    min-height: 22px !important;
    max-height: 22px !important;
}

.Forecast_editTableRow__hFttP {
    line-height: 3px !important;
    border: 1px solid red !important;
}

.Forecast_weekListTbody__uifK5>tr:nth-child(even) {
    background-color: rgb(225, 225, 225);
}

.Forecast_weekListTbody__uifK5>tr:nth-child(even).Forecast_menu-selected__dUOmi {
    background-color: rgb(210, 12, 12);
}

.Forecast_iconColumn__7Vpwg {
    padding-top: 4px !important;
}

.Forecast_iconColumn__7Vpwg>div>i,
.Forecast_iconColumn__7Vpwg>div>div>i {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 18px;
}

.Forecast_createOption__3p6cP {
    cursor: pointer;

}

.Forecast_containerCountAndTypeCol__20Ru8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    display: grid;
    grid-template-columns: 50% 50%;
    border: none !important;
}

.Forecast_requestNameCell__10HaQ,
.Forecast_assigneeCell__1UWuX {
    padding-left: 4px !important;
}

.Forecast_NEW__p6F4E {
    font-weight: 600;
    cursor: pointer;
}

.Forecast_REQUESTED__CAsaK {
    color: purple;
    font-weight: 600;
    cursor: pointer;
}

.Forecast_CONFIRMED__1kEdM {
    color: green;
    font-weight: 600;
    cursor: pointer;
}

.Forecast_DECLINED__2FLdC {
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.Forecast_NVA__1BWql {
    color: rgb(255, 174, 69);
    font-weight: 600;
    cursor: pointer;
}

.Forecast_DELETED__3VJZf {
    color: blue;
    font-weight: 600;
    cursor: pointer;
}

.Forecast_saveIcon__3ZhRn {
    margin-top: 4px;
    font-size: 18px
}

.Forecast_weekListCount__3q_Zf {
    position: absolute;
    bottom: 10px;
    left: 48%;
    z-index: 1;
}

.Forecast_standingListCount__3X96u {
    z-index: 1;
}

.Forecast_standingListCountContainer__3Sv1P {
    text-align: center;
    margin-bottom: 0px;
}

.Forecast_outlineRequestIndex__2TPz5 {
    box-shadow: inset 0px 0px 0px 0px rgb(0, 0, 0) !important;
}

.Forecast_outlineRequestIndex__2TPz5:focus {
    margin-left: -5px;
    border-radius: 5px;
    outline: solid #2196f3 !important;
}

.Forecast_iconContainer__k0jf7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
}

.Forecast_weekNumber__3Yc2k {
    text-align: center;
    font-weight: 600;
    font-size: 17px;
}

.create-menu-highlight {
    background-color: rgb(255, 199, 102)!important;
}
.globaltoast_toast__3Eopo {
    background-color: #444;
    border: solid 1px;
    color: #2196f3;
    border-color: #2196f3;
    border-radius: 3px;
}

.globaltoast_warning__2_zXm{
    border-color: #ff9800;
    color: #ff9800
}

.globaltoast_error__FO_XA{
    border-color: #f44336;
    color: #f44336;
}

.globaltoast_success__lF1A_{
    border-color: #00e676;
    color: #00e676;
}
* {
    margin: 0px;
    padding: 0px;
    border: none;
}

body {
    background-color: #fff;
    height: 100vh;
}

.container {
    margin-top: 12px;
    background-color: white;
    box-shadow: black 0px 2px 6px;
    border-radius: 2px;
}

#root {
    height: 100vh;
}

.body {
    height: calc(100vh - 112px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.sidebar {
    background-color: white;
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    margin: 0px;
}

.content {
    -webkit-flex: 6 1;
            flex: 6 1;
    height: 100%;
    box-shadow: -2px 0px 5px #aaa;
    z-index: 1;
}

.collection {
    margin: 0px;
    padding: 0px;
    max-width: 100%;
    height: 100%;
}
